// setting up the grid
@include gridle_setup ( (
	context : 24,
	gutter-width : $grid-gutter-width,
	direction : ltr,
	// etc...
) );

// iPhone 3 320x480 @1x
// iPhone 4 320x480 @2x
// iPhone 5 320x568 @2x
// iPhone 6 375x667 @3x
// iPhone 6+ 414x736 @3x

// register states
@include gridle_register_state( xs, (
	max-width : 320px
) );
@include gridle_register_state( sm, (
	min-width : 480px
) );
@include gridle_register_state( mb, (
	min-width : 667px
) );
@include gridle_register_state( tb , (
	min-width : 768px
) );
@include gridle_register_state( tb_max , (
	max-width : 768px
) );
@include gridle_register_state( md, (
	min-width : 992px
) );
@include gridle_register_state( lg, (
	min-width : 1200px
) );

// container sizes
[class="container"] {
	@include gridle_state( xs ) {
		width:100%;
	}
	@include gridle_state( sm ) {
		width:100%;
	}
	@include gridle_state( mb ) {
		width:100%;
	}
	@include gridle_state( tb ) {
		width:750px;
	}
	@include gridle_state( tb_max ) {
		max-width:750px;
	}
	@include gridle_state( md ) {
		width:970px;
	}
	@include gridle_state( lg ) {
		width:1170px;
	}
}

// mobile first approach
[class*="gr-"] {
	@include gridle_state( xs ) {
		@include gridle_grid( 24 );
	}
}

@include gridle_init();