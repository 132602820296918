// placeholders

// Chrome/Opera/Safari
::-webkit-input-placeholder {
  color: $text-color;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

:focus::-webkit-input-placeholder {
  opacity: .25;
}

// IE 10+
:-ms-input-placeholder {
  color: $text-color;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

:focus:-ms-input-placeholder {
  opacity: .25;
}

// Firefox 19+
::-moz-placeholder {
  color: $text-color;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

:focus::-moz-placeholder {
  opacity: .25;
}

// Firefox 4 - 18
:-moz-placeholder {
  color: $text-color;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

:focus:-moz-placeholder {
  opacity: .25;
}

// non-specific
::placeholder {
  color: $text-color;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}

:focus::placeholder {
  opacity: .25;
}

// add elipses on overflow

input[placeholder] {
  text-overflow: ellipsis;
}

::-moz-placeholder {
  text-overflow: ellipsis; // firefox 19+
}

input:-moz-placeholder {
  text-overflow: ellipsis;
}
