.page-content {

	padding: 40px;
	background: $white;

	@include gridle_state ( tb ) {

		padding: 60px;

	}

	@include gridle_state ( md lg ) {

		padding: 60px 100px 45px;

	}

}