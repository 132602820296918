/* =============================================================================
	Wrapper
	========================================================================== */

@mixin wrapper {

	width:100%;

}

/* =============================================================================
	Body Background Image
	========================================================================== */

@mixin body-bg-image {

	background-position: center center;
	background-repeat:no-repeat;
	background-attachment: fixed;

	/* lt ie8 */
	-ms-background-position-x: center;
	-ms-background-position-y: center;
	background-position: center center;

	/* scale bg image proportionately */
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
			
	/* ie8 workaround - http://louisremi.github.io/background-size-polyfill/ */
	-ms-behavior: url(/backgroundsize.min.htc);
	/* prevent scaling past src width (or not) */
	/* max-width: 1200px; */

}

/* =============================================================================
	Div Background Image
	========================================================================== */

@mixin div-bg-image {

	position:relative; 
	background-position: center center;
	background-repeat:no-repeat;

	/* lt ie8 */
	-ms-background-position-x: center;
	-ms-background-position-y: center;
	background-position: center center;

	/* scale bg image proportionately */
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	/* ie8 workaround - http://louisremi.github.io/background-size-polyfill/ */
	-ms-behavior: url(/backgroundsize.min.htc);
	/* prevent scaling past src width (or not) */
	/* max-width: 1200px; */

}

/* =============================================================================
	Border
	========================================================================== */

@mixin border {border:1px solid $hr-border;}
@mixin border-top {border-top:1px solid $hr-border;}
@mixin border-bottom {border-bottom:1px solid $hr-border;}

/* =============================================================================
	Corners
	========================================================================== */

@mixin corner-top-left ($corner-size: 20px, $outer-color: $white, $inner-color: $grey-light) {

	content: '';
	position: absolute;
	top: 0;
	left: 0;
	border-top: $corner-size solid $outer-color;
	border-right: $corner-size solid $inner-color;
	width: 0;

}

@mixin corner-top-right ($corner-size: 20px, $outer-color: $white, $inner-color: $grey-light) {

	content: '';
	position: absolute;
	top: 0;
	right: 0;
	border-top: $corner-size solid $outer-color;
	border-left: $corner-size solid $inner-color;
	width: 0;

}

@mixin corner-bottom-left ($corner-size: 20px, $outer-color: $white, $inner-color: $grey-light) {

	content: '';
	position: absolute;
	top: 0;
	left: 0;
	border-bottom: $corner-size solid $outer-color;
	border-right: $corner-size solid $inner-color;
	width: 0;

}

@mixin corner-bottom-right ($corner-size: 20px, $outer-color: $white, $inner-color: $grey-light) {

	content: '';
	position: absolute;
	top: 0;
	right: 0;
	border-bottom: $corner-size solid $outer-color;
	border-left: $corner-size solid $inner-color;
	width: 0;

}





