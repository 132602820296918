.box {

	position: relative;
	margin-bottom: 20px;
	padding: 26px !important;
	background-color: $brand-primary;

	&:before {

		@include corner-top-right(45px, $white, $brand-primary);

	}

	h3 {

		margin: 0 0 20px;
		padding: 0;
		font-size: $font-size-base;
		color: $white;

	}

	p {

		margin: 0 0 60px;
		padding: 0;
		font-size: $font-size-small;
		color: $white;

	}

	.btn {

		position: absolute;
		bottom: 26px;
		left: 26px;
		font-size: 12px;

	}

}

.box_highlight {

	background-color: $brand-secondary;

	&:before {

		@include corner-top-right(45px, $white, $brand-secondary);

	}

	img {

		margin-bottom: 20px;

	}

	h3 {

		margin: 0 0 10px;

	}

	p {

		margin: 0;

	}

	a {

		&:hover {

			color: $white;

		}

	}

}

.video {

	padding: 0 !important;

	@include div-bg-image;
	background-image: url(../images/video.jpg);


	h3 {

		margin: 0;
		padding: 14px 26px 13px;
		background-color: $brand-primary;
		font-size: $font-size-base;
		color: $white;

	}

	a {

		display: block;
		position: absolute;
 		top: 50%;
  	left: 50%;
		margin-top: -15px;
		margin-left: -40px;
		width: 80px;
		height: 80px;
		background-size: 80px 80px;
		background-image: url(../images/video-play.png);
		background-position: 0 0;
		background-repeat: no-repeat;

	}

}