ul.features {

	padding-top: 10px;
	font-size: 0;

	li {

		display: inline-block;
		position: relative;
		margin-bottom: 10px;
		margin-right: 0%;
		padding: 6px;
		padding-left: 38px;
		width: 100%;
		background-color: $brand-primary;
		background-size: 21px 20px;
		background-image: url(../images/bullet.png);
		background-position: 10px 9px;
		background-repeat: no-repeat;
		font-size: 13px;
		color: $white;
		font-weight: 600;

		&:before {

			@include corner-top-right(12px, $white, $brand-primary)

		}

	}

}