// forms

$form-font-family:              $font-family-sans-serif !default;
$form-font-size:                $font-size-base !default;
$form-line-height:              $form-font-size * 1.5 !default;
$form-space:                    $form-line-height / 2 !default;

$input-label-font-size:         $font-size-base !default;
$input-label-color:             $text-color !default;
$input-label-font-weight:       300 !default;

$input-bg:                      $white !default;
$input-border:                  $moon-gray !default;
$input-border-focus:            $brand-primary !default;
$input-error:                   $brand-danger !default;
$input-shadow:                  1px 1px 2px #eee inset !default;
$input-transition:              all .3s ease-in-out !default;

$input-font-size:               $font-size-base !default;
$input-color:                   $grey-darker !default;
$input-font-weight:             300 !default;

$form-required:                 $brand-danger !default;
$form-muted:                    #858585 !default;
