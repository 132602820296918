/* =============================================================================
	Footer
	========================================================================== */

footer {

	position: relative;
	margin-top: 40px;
	margin-bottom: 20px;
	padding-top: 40px;
	@include border-top;
	text-align: right;
	font-size: 13px;

	img {

		float: left;
		width: 100px;
		height: 35px;

	}

	nav {

		ul {

			margin-bottom: 13px;
			font-size: 0;

			li {

				display: block;
				margin-bottom: 13px;

				@include gridle_state ( tb md lg ) {

					display: inline-block;
					margin-right: 10px;
					padding-right: 10px;
					border-right: 1px solid $brand-secondary;

				}

				&:last-child {

					margin-right: 0;
					padding-right: 0;
					border-right: none;

				}

				a {

					font-size: 13px;
					line-height: 13px;

				}

			}

		}

	}

	p {

		margin-bottom: 13px;
		font-size: 13px;

	}

}