
// Converted Variables


// Custom Media Query Variables


/*

   MAX WIDTHS
   Docs: http://tachyons.io/docs/layout/max-widths/

   Base:
     mw = max-width

   Modifiers
     1 = 1st step in width scale
     2 = 2nd step in width scale
     3 = 3rd step in width scale
     4 = 4th step in width scale
     5 = 5th step in width scale
     6 = 6st step in width scale
     7 = 7nd step in width scale
     8 = 8rd step in width scale
     9 = 9th step in width scale

     -100 = literal value 100%

     -none  = string value none


   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

/* Max Width Percentages */

.mw-100  { max-width: 100%; }

/* Max Width Scale */

.mw1  {  max-width: $max-width-1; }
.mw2  {  max-width: $max-width-2; }
.mw3  {  max-width: $max-width-3; }
.mw4  {  max-width: $max-width-4; }
.mw5  {  max-width: $max-width-5; }
.mw6  {  max-width: $max-width-6; }
.mw7  {  max-width: $max-width-7; }
.mw8  {  max-width: $max-width-8; }
.mw9  {  max-width: $max-width-9; }

/* Max Width String Properties */

.mw-none { max-width: none; }

@media #{$breakpoint-not-small} {
  .mw-100-ns  { max-width: 100%; }

  .mw1-ns  {  max-width: $max-width-1; }
  .mw2-ns  {  max-width: $max-width-2; }
  .mw3-ns  {  max-width: $max-width-3; }
  .mw4-ns  {  max-width: $max-width-4; }
  .mw5-ns  {  max-width: $max-width-5; }
  .mw6-ns  {  max-width: $max-width-6; }
  .mw7-ns  {  max-width: $max-width-7; }
  .mw8-ns  {  max-width: $max-width-8; }
  .mw9-ns  {  max-width: $max-width-9; }

  .mw-none-ns { max-width: none; }
}

@media #{$breakpoint-tablet} {
  .mw-100-t  { max-width: 100%; }

  .mw1-t  {  max-width: $max-width-1; }
  .mw2-t  {  max-width: $max-width-2; }
  .mw3-t  {  max-width: $max-width-3; }
  .mw4-t  {  max-width: $max-width-4; }
  .mw5-t  {  max-width: $max-width-5; }
  .mw6-t  {  max-width: $max-width-6; }
  .mw7-t  {  max-width: $max-width-7; }
  .mw8-t  {  max-width: $max-width-8; }
  .mw9-t  {  max-width: $max-width-9; }

  .mw-none-ns { max-width: none; }
}

@media #{$breakpoint-medium} {
  .mw-100-m  { max-width: 100%; }

  .mw1-m  {  max-width: $max-width-1; }
  .mw2-m  {  max-width: $max-width-2; }
  .mw3-m  {  max-width: $max-width-3; }
  .mw4-m  {  max-width: $max-width-4; }
  .mw5-m  {  max-width: $max-width-5; }
  .mw6-m  {  max-width: $max-width-6; }
  .mw7-m  {  max-width: $max-width-7; }
  .mw8-m  {  max-width: $max-width-8; }
  .mw9-m  {  max-width: $max-width-9; }

  .mw-none-m { max-width: none; }
}

@media #{$breakpoint-large} {
  .mw-100-l  { max-width: 100%; }

  .mw1-l  {  max-width: $max-width-1; }
  .mw2-l  {  max-width: $max-width-2; }
  .mw3-l  {  max-width: $max-width-3; }
  .mw4-l  {  max-width: $max-width-4; }
  .mw5-l  {  max-width: $max-width-5; }
  .mw6-l  {  max-width: $max-width-6; }
  .mw7-l  {  max-width: $max-width-7; }
  .mw8-l  {  max-width: $max-width-8; }
  .mw9-l  {  max-width: $max-width-9; }

  .mw-none-l { max-width: none; }
}
