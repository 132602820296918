/* =============================================================================
	Header
	========================================================================== */

header {

	position: relative;
	margin-bottom: 20px;
	padding-top: 40px;
	padding-bottom: 30px;
	@include border-bottom;

	.brand {

		width: 190px; height: 60px; background-size: 190px 60px; background-image: url(../images/logo.png);

		@include gridle_state ( tb md lg ) {

			width: 280px; height: 89px; background-size: 280px 89px;

		}

	}

	nav {

		position: absolute;
		top: 56px;
		right: 10px;

		@include gridle_state ( tb md lg ) {

			top: 83px;

		}

	}

}