.cta_block {

  position: relative;
  padding: 40px;
  background-color: $brand-secondary;

  @include gridle_state ( tb ) {

		padding: 40px 60px;

	}

	@include gridle_state ( md lg ) {

		padding: 40px 110px;

	}

  &:before {

    @include corner-top-right(45px, $white, $brand-secondary);

  }

  .cta_content {

  	margin: 0 0 32px;
  	font-size: 24px;
  	color: $white;
  	line-height: 28px;
  	font-weight: 600;
  	text-transform: uppercase;

  	@include gridle_state ( tb ) {

      margin: -5px 0 0;
      font-size: 20px;
      line-height: 34px;

    }

    @include gridle_state ( md ) {

      font-size: 26px;

    }

    @include gridle_state ( lg ) {

  		font-size: 28px;

  	}

  }

  .next_event {

  	h4 {

  		margin: 0 0 16px;
  		font-size: $font-size-base;
  		color: $white;

  	}

  	p {

  		margin-bottom: 0;
  		font-size: $font-size-small;
  		color: $white;
  		line-height: $line-height-small;

  	}

  	#vsel {

  		float: none;
  		margin: 0;
  		padding:0;

  		.vsel-content {

  			float: none;
  			margin: 0;
  			padding:0;
  			border-bottom: none;

  			.vsel-meta {

		 		display: block;
				float: none;
				width: 100%;

				h4 {

			  		margin: 0 0 4px;
			  		font-size: $font-size-base;
			  		color: $white;

			  }

        p {

            margin: 0;

        }

		  }

      .vsel-info {

        display: none;
        visibility: hidden;

      }

  	}

	}

}

}