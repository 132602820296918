
// Converted Variables


// Custom Media Query Variables


/*

   FORMS

*/

.input-reset {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.button-reset::-moz-focus-inner,
.input-reset::-moz-focus-inner {
  border: 0;
  padding: 0;
}
