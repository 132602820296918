.cc-1 { -webkit-column-count: 1; -moz-column-count: 1; column-count: 1; }
.cc-2 { -webkit-column-count: 2; -moz-column-count: 2; column-count: 2; }
.cc-3 { -webkit-column-count: 3; -moz-column-count: 3; column-count: 3; }
.cc-4 { -webkit-column-count: 4; -moz-column-count: 4; column-count: 4; }
.cc-5 { -webkit-column-count: 5; -moz-column-count: 5; column-count: 5; }
.cc-6 { -webkit-column-count: 6; -moz-column-count: 6; column-count: 6; }
.cc-7 { -webkit-column-count: 7; -moz-column-count: 7; column-count: 7; }
.cc-8 { -webkit-column-count: 8; -moz-column-count: 8; column-count: 8; }
.cc-9 { -webkit-column-count: 9; -moz-column-count: 9; column-count: 9; }
.cc-10 { -webkit-column-count: 10; -moz-column-count: 10; column-count: 10; }
.cc-11 { -webkit-column-count: 11; -moz-column-count: 11; column-count: 11; }
.cc-12 { -webkit-column-count: 12; -moz-column-count: 12; column-count: 12; }
.cc-13 { -webkit-column-count: 13; -moz-column-count: 13; column-count: 13; }
.cc-14 { -webkit-column-count: 14; -moz-column-count: 14; column-count: 14; }
.cc-15 { -webkit-column-count: 15; -moz-column-count: 15; column-count: 15; }
.cc-16 { -webkit-column-count: 16; -moz-column-count: 16; column-count: 16; }
.cc-auto { -webkit-column-count: auto; -moz-column-count: auto; column-count: auto; }

@media #{$breakpoint-not-small} {
  .cc-1-ns { -webkit-column-count: 1; -moz-column-count: 1; column-count: 1; }
  .cc-2-ns { -webkit-column-count: 2; -moz-column-count: 2; column-count: 2; }
  .cc-3-ns { -webkit-column-count: 3; -moz-column-count: 3; column-count: 3; }
  .cc-4-ns { -webkit-column-count: 4; -moz-column-count: 4; column-count: 4; }
  .cc-5-ns { -webkit-column-count: 5; -moz-column-count: 5; column-count: 5; }
  .cc-6-ns { -webkit-column-count: 6; -moz-column-count: 6; column-count: 6; }
  .cc-7-ns { -webkit-column-count: 7; -moz-column-count: 7; column-count: 7; }
  .cc-8-ns { -webkit-column-count: 8; -moz-column-count: 8; column-count: 8; }
  .cc-9-ns { -webkit-column-count: 9; -moz-column-count: 9; column-count: 9; }
  .cc-10-ns { -webkit-column-count: 10; -moz-column-count: 10; column-count: 10; }
  .cc-11-ns { -webkit-column-count: 11; -moz-column-count: 11; column-count: 11; }
  .cc-12-ns { -webkit-column-count: 12; -moz-column-count: 12; column-count: 12; }
  .cc-13-ns { -webkit-column-count: 13; -moz-column-count: 13; column-count: 13; }
  .cc-14-ns { -webkit-column-count: 14; -moz-column-count: 14; column-count: 14; }
  .cc-15-ns { -webkit-column-count: 15; -moz-column-count: 15; column-count: 15; }
  .cc-16-ns { -webkit-column-count: 16; -moz-column-count: 16; column-count: 16; }
  .cc-auto-ns { -webkit-column-count: auto; -moz-column-count: auto; column-count: auto; }
}

@media #{$breakpoint-tablet} {
  .cc-1-t { -webkit-column-count: 1; -moz-column-count: 1; column-count: 1; }
  .cc-2-t { -webkit-column-count: 2; -moz-column-count: 2; column-count: 2; }
  .cc-3-t { -webkit-column-count: 3; -moz-column-count: 3; column-count: 3; }
  .cc-4-t { -webkit-column-count: 4; -moz-column-count: 4; column-count: 4; }
  .cc-5-t { -webkit-column-count: 5; -moz-column-count: 5; column-count: 5; }
  .cc-6-t { -webkit-column-count: 6; -moz-column-count: 6; column-count: 6; }
  .cc-7-t { -webkit-column-count: 7; -moz-column-count: 7; column-count: 7; }
  .cc-8-t { -webkit-column-count: 8; -moz-column-count: 8; column-count: 8; }
  .cc-9-t { -webkit-column-count: 9; -moz-column-count: 9; column-count: 9; }
  .cc-10-t { -webkit-column-count: 10; -moz-column-count: 10; column-count: 10; }
  .cc-11-t { -webkit-column-count: 11; -moz-column-count: 11; column-count: 11; }
  .cc-12-t { -webkit-column-count: 12; -moz-column-count: 12; column-count: 12; }
  .cc-13-t { -webkit-column-count: 13; -moz-column-count: 13; column-count: 13; }
  .cc-14-t { -webkit-column-count: 14; -moz-column-count: 14; column-count: 14; }
  .cc-15-t { -webkit-column-count: 15; -moz-column-count: 15; column-count: 15; }
  .cc-16-t { -webkit-column-count: 16; -moz-column-count: 16; column-count: 16; }
  .cc-auto-t { -webkit-column-count: auto; -moz-column-count: auto; column-count: auto; }
}

@media #{$breakpoint-medium} {
  .cc-1-m { -webkit-column-count: 1; -moz-column-count: 1; column-count: 1; }
  .cc-2-m { -webkit-column-count: 2; -moz-column-count: 2; column-count: 2; }
  .cc-3-m { -webkit-column-count: 3; -moz-column-count: 3; column-count: 3; }
  .cc-4-m { -webkit-column-count: 4; -moz-column-count: 4; column-count: 4; }
  .cc-5-m { -webkit-column-count: 5; -moz-column-count: 5; column-count: 5; }
  .cc-6-m { -webkit-column-count: 6; -moz-column-count: 6; column-count: 6; }
  .cc-7-m { -webkit-column-count: 7; -moz-column-count: 7; column-count: 7; }
  .cc-8-m { -webkit-column-count: 8; -moz-column-count: 8; column-count: 8; }
  .cc-9-m { -webkit-column-count: 9; -moz-column-count: 9; column-count: 9; }
  .cc-10-m { -webkit-column-count: 10; -moz-column-count: 10; column-count: 10; }
  .cc-11-m { -webkit-column-count: 11; -moz-column-count: 11; column-count: 11; }
  .cc-12-m { -webkit-column-count: 12; -moz-column-count: 12; column-count: 12; }
  .cc-13-m { -webkit-column-count: 13; -moz-column-count: 13; column-count: 13; }
  .cc-14-m { -webkit-column-count: 14; -moz-column-count: 14; column-count: 14; }
  .cc-15-m { -webkit-column-count: 15; -moz-column-count: 15; column-count: 15; }
  .cc-16-m { -webkit-column-count: 16; -moz-column-count: 16; column-count: 16; }
  .cc-auto-m { -webkit-column-count: auto; -moz-column-count: auto; column-count: auto; }
}

@media #{$breakpoint-large} {
  .cc-1-l { -webkit-column-count: 1; -moz-column-count: 1; column-count: 1; }
  .cc-2-l { -webkit-column-count: 2; -moz-column-count: 2; column-count: 2; }
  .cc-3-l { -webkit-column-count: 3; -moz-column-count: 3; column-count: 3; }
  .cc-4-l { -webkit-column-count: 4; -moz-column-count: 4; column-count: 4; }
  .cc-5-l { -webkit-column-count: 5; -moz-column-count: 5; column-count: 5; }
  .cc-6-l { -webkit-column-count: 6; -moz-column-count: 6; column-count: 6; }
  .cc-7-l { -webkit-column-count: 7; -moz-column-count: 7; column-count: 7; }
  .cc-8-l { -webkit-column-count: 8; -moz-column-count: 8; column-count: 8; }
  .cc-9-l { -webkit-column-count: 9; -moz-column-count: 9; column-count: 9; }
  .cc-10-l { -webkit-column-count: 10; -moz-column-count: 10; column-count: 10; }
  .cc-11-l { -webkit-column-count: 11; -moz-column-count: 11; column-count: 11; }
  .cc-12-l { -webkit-column-count: 12; -moz-column-count: 12; column-count: 12; }
  .cc-13-l { -webkit-column-count: 13; -moz-column-count: 13; column-count: 13; }
  .cc-14-l { -webkit-column-count: 14; -moz-column-count: 14; column-count: 14; }
  .cc-15-l { -webkit-column-count: 15; -moz-column-count: 15; column-count: 15; }
  .cc-16-l { -webkit-column-count: 16; -moz-column-count: 16; column-count: 16; }
  .cc-auto-l { -webkit-column-count: auto; -moz-column-count: auto; column-count: auto; }
}
