ul#accordion, ul#training_accordion, ul#investment_accordion {

	margin:32px 0;
	width:100%;
	background:$white;
	border-top:1px solid $white;
	border-bottom:1px solid $white;
	list-style: none;
	list-style-type: none;

	li {

		margin:0;
		padding:0;
		position:relative;
		border-bottom:1px solid $white;
		list-style:none;
		list-style-type:none;
		clear:both;
		&:before {content:""; position:relative;}

		ul {

			margin:0;

			li {

				padding: 20px 10px;
				border-bottom: 4px solid $brand-secondary;
				font-weight: 400;
				clear: both;

				&:before {content:""; position:relative;}

			}

		}

		a {

			display:block;
			padding:10px;
			height: auto;
			background:$accordion-title-bg-color;
			color:$accordion-title-font-color;
			text-decoration:none;

			&:before {

				position:absolute;
				top:0;
				right:0;
				width:36px;
				height:36px;
				content:"";
				background-size: 11px 20px;
				background-image:url(../images/arrow-right.png);
				background-position:12px 10px;
				background-repeat:no-repeat;
				background-color:$accordion-arrow-bg-color;

			}

			&:hover {

				background:$accordion-title-bg-hover-color;
				color:$accordion-title-font-hover-color;

				&:before {

					background-color:$accordion-arrow-bg-hover-color;

				}

			}

			h3 {

				margin:0;
				font-size:$font-size-large;
				color:$accordion-title-font-color;

			}

		}

		a.active {

			background:$accordion-title-bg-active-color;
			color:$accordion-title-font-active-color;

			&:before {

				position:absolute;
				top:0;
				right:0;
				width:36px;
				height:36px;
				content:"";
				background-size: 20px 11px;
				background-image:url(../images/arrow-down.png);
				background-position:8px 15px;
				background-repeat:no-repeat;
				background-color:$accordion-arrow-bg-hover-color;

			}

			&:hover {

				&:before {

					background-color:$accordion-arrow-bg-hover-color;

				}

			}

		}

	}

}