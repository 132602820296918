
header {

	@include gridle ( 24 );

}

.main {

	@include gridle ( 24 md 18 );

}

aside {

	@include gridle ( 24 md 6 );

	.box_highlight_wrap {

		@include gridle ( 24 );
		@include gridle_state ( md lg ) {

			@include gridle_no_gutter();

		}

	}

	.box_wrap {

		@include gridle ( 24 mb 8 md 24 );
		@include gridle_no_gutter();

		@include gridle_state ( mb tb ) {

			@include gridle_gutter();

		}
		@include gridle_state ( md lg ) {

			@include gridle_no_gutter();

		}

	}

}

.cta {

	@include gridle ( 24 );

	.cta_content {

	  	@include gridle ( 24 tb 16 );
	  	@include gridle_no_gutter();

	}

	.next_event {

	  	@include gridle ( 24 tb 7 prefix 1 );
	  	@include gridle_no_gutter();

	}

}

footer {

	@include gridle ( 24 );

}