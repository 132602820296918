.page-content .video--product {
  @include div-bg-image;
  padding: 0 !important;
  height: 280px;

  @include gridle_state (tb) {
    height: 370px;
  }


  h3 {
    padding: 14px 26px 13px !important;
    margin: 0 !important;
    background-color: $brand-primary;
    font-size: $font-size-base;
    color: $white;
  }

  a {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -15px;
    margin-left: -40px;
    width: 80px;
    height: 80px;
    background-size: 80px 80px;
    background-image: url(../images/video-play.png);
    background-position: 0 0;
    background-repeat: no-repeat;
  }
}

.page-content .product--moto {
  background-image: url('../images/video-screenshot--moto.jpg');
}

.page-content .product--mini {
  background-image: url('../images/video-screenshot--mini.jpg');
}

.page-content .product--decartoner {
  background-image: url('../images/video-screenshot--decartoner.jpg');
}
