
// Converted Variables


// Custom Media Query Variables


/* Variables */

/*
   SPACING
   Docs: http://tachyons.io/docs/layout/spacing/

   An eight step powers of two scale ranging from 0 to 16rem.

   Base:
     p = padding
     m = margin

   Modifiers:
     a = all
     h = horizontal
     v = vertical
     t = top
     r = right
     b = bottom
     l = left

     0 = none
     1 = 1st step in spacing scale
     2 = 2nd step in spacing scale
     3 = 3rd step in spacing scale
     4 = 4th step in spacing scale
     5 = 5th step in spacing scale
     6 = 6th step in spacing scale
     7 = 7th step in spacing scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/


.pa0 { padding: $spacing-none; }
.pa1 { padding: $spacing-extra-small; }
.pa2 { padding: $spacing-small; }
.pa3 { padding: $spacing-medium; }
.pa4 { padding: $spacing-large; }
.pa5 { padding: $spacing-extra-large; }
.pa6 { padding: $spacing-extra-extra-large; }
.pa7 { padding: $spacing-extra-extra-extra-large; }

.pl0 { padding-left: $spacing-none; }
.pl1 { padding-left: $spacing-extra-small; }
.pl2 { padding-left: $spacing-small; }
.pl3 { padding-left: $spacing-medium; }
.pl4 { padding-left: $spacing-large; }
.pl5 { padding-left: $spacing-extra-large; }
.pl6 { padding-left: $spacing-extra-extra-large; }
.pl7 { padding-left: $spacing-extra-extra-extra-large; }

.pr0 { padding-right: $spacing-none; }
.pr1 { padding-right: $spacing-extra-small; }
.pr2 { padding-right: $spacing-small; }
.pr3 { padding-right: $spacing-medium; }
.pr4 { padding-right: $spacing-large; }
.pr5 { padding-right: $spacing-extra-large; }
.pr6 { padding-right: $spacing-extra-extra-large; }
.pr7 { padding-right: $spacing-extra-extra-extra-large; }

.pb0 { padding-bottom: $spacing-none; }
.pb1 { padding-bottom: $spacing-extra-small; }
.pb2 { padding-bottom: $spacing-small; }
.pb3 { padding-bottom: $spacing-medium; }
.pb4 { padding-bottom: $spacing-large; }
.pb5 { padding-bottom: $spacing-extra-large; }
.pb6 { padding-bottom: $spacing-extra-extra-large; }
.pb7 { padding-bottom: $spacing-extra-extra-extra-large; }

.pt0 { padding-top: $spacing-none; }
.pt1 { padding-top: $spacing-extra-small; }
.pt2 { padding-top: $spacing-small; }
.pt3 { padding-top: $spacing-medium; }
.pt4 { padding-top: $spacing-large; }
.pt5 { padding-top: $spacing-extra-large; }
.pt6 { padding-top: $spacing-extra-extra-large; }
.pt7 { padding-top: $spacing-extra-extra-extra-large; }

.pv0 {
  padding-top: $spacing-none;
  padding-bottom: $spacing-none;
}
.pv1 {
  padding-top: $spacing-extra-small;
  padding-bottom: $spacing-extra-small;
}
.pv2 {
  padding-top: $spacing-small;
  padding-bottom: $spacing-small;
}
.pv3 {
  padding-top: $spacing-medium;
  padding-bottom: $spacing-medium;
}
.pv4 {
  padding-top: $spacing-large;
  padding-bottom: $spacing-large;
}
.pv5 {
  padding-top: $spacing-extra-large;
  padding-bottom: $spacing-extra-large;
}
.pv6 {
  padding-top: $spacing-extra-extra-large;
  padding-bottom: $spacing-extra-extra-large;
}

.pv7 {
  padding-top: $spacing-extra-extra-extra-large;
  padding-bottom: $spacing-extra-extra-extra-large;
}

.ph0 {
  padding-left: $spacing-none;
  padding-right: $spacing-none;
}

.ph1 {
  padding-left: $spacing-extra-small;
  padding-right: $spacing-extra-small;
}

.ph2 {
  padding-left: $spacing-small;
  padding-right: $spacing-small;
}

.ph3 {
  padding-left: $spacing-medium;
  padding-right: $spacing-medium;
}

.ph4 {
  padding-left: $spacing-large;
  padding-right: $spacing-large;
}

.ph5 {
  padding-left: $spacing-extra-large;
  padding-right: $spacing-extra-large;
}

.ph6 {
  padding-left: $spacing-extra-extra-large;
  padding-right: $spacing-extra-extra-large;
}

.ph7 {
  padding-left: $spacing-extra-extra-extra-large;
  padding-right: $spacing-extra-extra-extra-large;
}

.ml-auto { margin-left: $spacing-auto; }
.mr-auto { margin-right: $spacing-auto; }

.ma0 { margin: $spacing-none; }
.ma1 { margin: $spacing-extra-small; }
.ma2 { margin: $spacing-small; }
.ma3 { margin: $spacing-medium; }
.ma4 { margin: $spacing-large; }
.ma5 { margin: $spacing-extra-large; }
.ma6 { margin: $spacing-extra-extra-large; }
.ma7 { margin: $spacing-extra-extra-extra-large; }

.ml0 { margin-left: $spacing-none; }
.ml1 { margin-left: $spacing-extra-small; }
.ml2 { margin-left: $spacing-small; }
.ml3 { margin-left: $spacing-medium; }
.ml4 { margin-left: $spacing-large; }
.ml5 { margin-left: $spacing-extra-large; }
.ml6 { margin-left: $spacing-extra-extra-large; }
.ml7 { margin-left: $spacing-extra-extra-extra-large; }

.mr0 { margin-right: $spacing-none; }
.mr1 { margin-right: $spacing-extra-small; }
.mr2 { margin-right: $spacing-small; }
.mr3 { margin-right: $spacing-medium; }
.mr4 { margin-right: $spacing-large; }
.mr5 { margin-right: $spacing-extra-large; }
.mr6 { margin-right: $spacing-extra-extra-large; }
.mr7 { margin-right: $spacing-extra-extra-extra-large; }

.mb0  {  margin-bottom: $spacing-none; }
.mb1 {  margin-bottom: $spacing-extra-small; }
.mb2  {  margin-bottom: $spacing-small; }
.mb3  {  margin-bottom: $spacing-medium; }
.mb4  {  margin-bottom: $spacing-large; }
.mb5  {  margin-bottom: $spacing-extra-large; }
.mb6 {  margin-bottom: $spacing-extra-extra-large; }
.mb7 { margin-bottom: $spacing-extra-extra-extra-large; }

.mt0  {  margin-top: $spacing-none; }
.mt1 {  margin-top: $spacing-extra-small; }
.mt2  {  margin-top: $spacing-small; }
.mt3  {  margin-top: $spacing-medium; }
.mt4  {  margin-top: $spacing-large; }
.mt5  {  margin-top: $spacing-extra-large; }
.mt6 {  margin-top: $spacing-extra-extra-large; }
.mt7 { margin-top: $spacing-extra-extra-extra-large; }

.mv0   {
  margin-top: $spacing-none;
  margin-bottom: $spacing-none;
}
.mv1  {
  margin-top: $spacing-extra-small;
  margin-bottom: $spacing-extra-small;
}
.mv2   {
  margin-top: $spacing-small;
  margin-bottom: $spacing-small;
}
.mv3   {
  margin-top: $spacing-medium;
  margin-bottom: $spacing-medium;
}
.mv4   {
  margin-top: $spacing-large;
  margin-bottom: $spacing-large;
}
.mv5   {
  margin-top: $spacing-extra-large;
  margin-bottom: $spacing-extra-large;
}
.mv6  {
  margin-top: $spacing-extra-extra-large;
  margin-bottom: $spacing-extra-extra-large;
}
.mv7  {
  margin-top: $spacing-extra-extra-extra-large;
  margin-bottom: $spacing-extra-extra-extra-large;
}

.mh0   {
  margin-left: $spacing-none;
  margin-right: $spacing-none;
}
.mh1   {
  margin-left: $spacing-extra-small;
  margin-right: $spacing-extra-small;
}
.mh2   {
  margin-left: $spacing-small;
  margin-right: $spacing-small;
}
.mh3   {
  margin-left: $spacing-medium;
  margin-right: $spacing-medium;
}
.mh4   {
  margin-left: $spacing-large;
  margin-right: $spacing-large;
}
.mh5   {
  margin-left: $spacing-extra-large;
  margin-right: $spacing-extra-large;
}
.mh6  {
  margin-left: $spacing-extra-extra-large;
  margin-right: $spacing-extra-extra-large;
}
.mh7  {
  margin-left: $spacing-extra-extra-extra-large;
  margin-right: $spacing-extra-extra-extra-large;
}

@media #{$breakpoint-not-small} {
  .pa0-ns  {  padding: $spacing-none; }
  .pa1-ns {  padding: $spacing-extra-small; }
  .pa2-ns  {  padding: $spacing-small; }
  .pa3-ns  {  padding: $spacing-medium; }
  .pa4-ns  {  padding: $spacing-large; }
  .pa5-ns  {  padding: $spacing-extra-large; }
  .pa6-ns {  padding: $spacing-extra-extra-large; }
  .pa7-ns { padding: $spacing-extra-extra-extra-large; }

  .pl0-ns  {  padding-left: $spacing-none; }
  .pl1-ns {  padding-left: $spacing-extra-small; }
  .pl2-ns  {  padding-left: $spacing-small; }
  .pl3-ns  {  padding-left: $spacing-medium; }
  .pl4-ns  {  padding-left: $spacing-large; }
  .pl5-ns  {  padding-left: $spacing-extra-large; }
  .pl6-ns {  padding-left: $spacing-extra-extra-large; }
  .pl7-ns { padding-left: $spacing-extra-extra-extra-large; }

  .pr0-ns  {  padding-right: $spacing-none; }
  .pr1-ns {  padding-right: $spacing-extra-small; }
  .pr2-ns  {  padding-right: $spacing-small; }
  .pr3-ns  {  padding-right: $spacing-medium; }
  .pr4-ns  {  padding-right: $spacing-large; }
  .pr5-ns {   padding-right: $spacing-extra-large; }
  .pr6-ns {  padding-right: $spacing-extra-extra-large; }
  .pr7-ns { padding-right: $spacing-extra-extra-extra-large; }

  .pb0-ns  {  padding-bottom: $spacing-none; }
  .pb1-ns {  padding-bottom: $spacing-extra-small; }
  .pb2-ns  {  padding-bottom: $spacing-small; }
  .pb3-ns  {  padding-bottom: $spacing-medium; }
  .pb4-ns  {  padding-bottom: $spacing-large; }
  .pb5-ns  {  padding-bottom: $spacing-extra-large; }
  .pb6-ns {  padding-bottom: $spacing-extra-extra-large; }
  .pb7-ns { padding-bottom: $spacing-extra-extra-extra-large; }

  .pt0-ns  {  padding-top: $spacing-none; }
  .pt1-ns {  padding-top: $spacing-extra-small; }
  .pt2-ns  {  padding-top: $spacing-small; }
  .pt3-ns  {  padding-top: $spacing-medium; }
  .pt4-ns  {  padding-top: $spacing-large; }
  .pt5-ns  {  padding-top: $spacing-extra-large; }
  .pt6-ns {  padding-top: $spacing-extra-extra-large; }
  .pt7-ns { padding-top: $spacing-extra-extra-extra-large; }

  .pv0-ns {
    padding-top: $spacing-none;
    padding-bottom: $spacing-none;
  }
  .pv1-ns {
    padding-top: $spacing-extra-small;
    padding-bottom: $spacing-extra-small;
  }
  .pv2-ns {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }
  .pv3-ns {
    padding-top: $spacing-medium;
    padding-bottom: $spacing-medium;
  }
  .pv4-ns {
    padding-top: $spacing-large;
    padding-bottom: $spacing-large;
  }
  .pv5-ns {
    padding-top: $spacing-extra-large;
    padding-bottom: $spacing-extra-large;
  }
  .pv6-ns {
    padding-top: $spacing-extra-extra-large;
    padding-bottom: $spacing-extra-extra-large;
  }
  .pv7-ns {
    padding-top: $spacing-extra-extra-extra-large;
    padding-bottom: $spacing-extra-extra-extra-large;
  }
  .ph0-ns {
    padding-left: $spacing-none;
    padding-right: $spacing-none;
  }
  .ph1-ns {
    padding-left: $spacing-extra-small;
    padding-right: $spacing-extra-small;
  }
  .ph2-ns {
    padding-left: $spacing-small;
    padding-right: $spacing-small;
  }
  .ph3-ns {
    padding-left: $spacing-medium;
    padding-right: $spacing-medium;
  }
  .ph4-ns {
    padding-left: $spacing-large;
    padding-right: $spacing-large;
  }
  .ph5-ns {
    padding-left: $spacing-extra-large;
    padding-right: $spacing-extra-large;
  }
  .ph6-ns {
    padding-left: $spacing-extra-extra-large;
    padding-right: $spacing-extra-extra-large;
  }
  .ph7-ns {
    padding-left: $spacing-extra-extra-extra-large;
    padding-right: $spacing-extra-extra-extra-large;
  }

  .ml-auto-ns { margin-left: $spacing-auto; }
  .mr-auto-ns { margin-right: $spacing-auto; }

  .ma0-ns  {  margin: $spacing-none; }
  .ma1-ns {  margin: $spacing-extra-small; }
  .ma2-ns  {  margin: $spacing-small; }
  .ma3-ns  {  margin: $spacing-medium; }
  .ma4-ns  {  margin: $spacing-large; }
  .ma5-ns  {  margin: $spacing-extra-large; }
  .ma6-ns {  margin: $spacing-extra-extra-large; }
  .ma7-ns { margin: $spacing-extra-extra-extra-large; }

  .ml0-ns  {  margin-left: $spacing-none; }
  .ml1-ns {  margin-left: $spacing-extra-small; }
  .ml2-ns  {  margin-left: $spacing-small; }
  .ml3-ns  {  margin-left: $spacing-medium; }
  .ml4-ns  {  margin-left: $spacing-large; }
  .ml5-ns  {  margin-left: $spacing-extra-large; }
  .ml6-ns {  margin-left: $spacing-extra-extra-large; }
  .ml7-ns { margin-left: $spacing-extra-extra-extra-large; }

  .mr0-ns  {  margin-right: $spacing-none; }
  .mr1-ns {  margin-right: $spacing-extra-small; }
  .mr2-ns  {  margin-right: $spacing-small; }
  .mr3-ns  {  margin-right: $spacing-medium; }
  .mr4-ns  {  margin-right: $spacing-large; }
  .mr5-ns  {  margin-right: $spacing-extra-large; }
  .mr6-ns {  margin-right: $spacing-extra-extra-large; }
  .mr7-ns { margin-right: $spacing-extra-extra-extra-large; }

  .mb0-ns  {  margin-bottom: $spacing-none; }
  .mb1-ns {  margin-bottom: $spacing-extra-small; }
  .mb2-ns  {  margin-bottom: $spacing-small; }
  .mb3-ns  {  margin-bottom: $spacing-medium; }
  .mb4-ns  {  margin-bottom: $spacing-large; }
  .mb5-ns  {  margin-bottom: $spacing-extra-large; }
  .mb6-ns {  margin-bottom: $spacing-extra-extra-large; }
  .mb7-ns { margin-bottom: $spacing-extra-extra-extra-large; }

  .mt0-ns  {  margin-top: $spacing-none; }
  .mt1-ns {  margin-top: $spacing-extra-small; }
  .mt2-ns  {  margin-top: $spacing-small; }
  .mt3-ns  {  margin-top: $spacing-medium; }
  .mt4-ns  {  margin-top: $spacing-large; }
  .mt5-ns  {  margin-top: $spacing-extra-large; }
  .mt6-ns {  margin-top: $spacing-extra-extra-large; }
  .mt7-ns { margin-top: $spacing-extra-extra-extra-large; }

  .mv0-ns   {
    margin-top: $spacing-none;
    margin-bottom: $spacing-none;
  }
  .mv1-ns  {
    margin-top: $spacing-extra-small;
    margin-bottom: $spacing-extra-small;
  }
  .mv2-ns   {
    margin-top: $spacing-small;
    margin-bottom: $spacing-small;
  }
  .mv3-ns   {
    margin-top: $spacing-medium;
    margin-bottom: $spacing-medium;
  }
  .mv4-ns   {
    margin-top: $spacing-large;
    margin-bottom: $spacing-large;
  }
  .mv5-ns   {
    margin-top: $spacing-extra-large;
    margin-bottom: $spacing-extra-large;
  }
  .mv6-ns  {
    margin-top: $spacing-extra-extra-large;
    margin-bottom: $spacing-extra-extra-large;
  }
  .mv7-ns  {
    margin-top: $spacing-extra-extra-extra-large;
    margin-bottom: $spacing-extra-extra-extra-large;
  }

  .mh0-ns   {
    margin-left: $spacing-none;
    margin-right: $spacing-none;
  }
  .mh1-ns   {
    margin-left: $spacing-extra-small;
    margin-right: $spacing-extra-small;
  }
  .mh2-ns   {
    margin-left: $spacing-small;
    margin-right: $spacing-small;
  }
  .mh3-ns   {
    margin-left: $spacing-medium;
    margin-right: $spacing-medium;
  }
  .mh4-ns   {
    margin-left: $spacing-large;
    margin-right: $spacing-large;
  }
  .mh5-ns   {
    margin-left: $spacing-extra-large;
    margin-right: $spacing-extra-large;
  }
  .mh6-ns  {
    margin-left: $spacing-extra-extra-large;
    margin-right: $spacing-extra-extra-large;
  }
  .mh7-ns  {
    margin-left: $spacing-extra-extra-extra-large;
    margin-right: $spacing-extra-extra-extra-large;
  }

}

@media #{$breakpoint-tablet} {
  .pa0-t  {  padding: $spacing-none; }
  .pa1-t {  padding: $spacing-extra-small; }
  .pa2-t  {  padding: $spacing-small; }
  .pa3-t  {  padding: $spacing-medium; }
  .pa4-t  {  padding: $spacing-large; }
  .pa5-t  {  padding: $spacing-extra-large; }
  .pa6-t {  padding: $spacing-extra-extra-large; }
  .pa7-t { padding: $spacing-extra-extra-extra-large; }

  .pl0-t  {  padding-left: $spacing-none; }
  .pl1-t {  padding-left: $spacing-extra-small; }
  .pl2-t  {  padding-left: $spacing-small; }
  .pl3-t  {  padding-left: $spacing-medium; }
  .pl4-t  {  padding-left: $spacing-large; }
  .pl5-t  {  padding-left: $spacing-extra-large; }
  .pl6-t {  padding-left: $spacing-extra-extra-large; }
  .pl7-t { padding-left: $spacing-extra-extra-extra-large; }

  .pr0-t  {  padding-right: $spacing-none; }
  .pr1-t {  padding-right: $spacing-extra-small; }
  .pr2-t  {  padding-right: $spacing-small; }
  .pr3-t  {  padding-right: $spacing-medium; }
  .pr4-t  {  padding-right: $spacing-large; }
  .pr5-t {   padding-right: $spacing-extra-large; }
  .pr6-t {  padding-right: $spacing-extra-extra-large; }
  .pr7-t { padding-right: $spacing-extra-extra-extra-large; }

  .pb0-t  {  padding-bottom: $spacing-none; }
  .pb1-t {  padding-bottom: $spacing-extra-small; }
  .pb2-t  {  padding-bottom: $spacing-small; }
  .pb3-t  {  padding-bottom: $spacing-medium; }
  .pb4-t  {  padding-bottom: $spacing-large; }
  .pb5-t  {  padding-bottom: $spacing-extra-large; }
  .pb6-t {  padding-bottom: $spacing-extra-extra-large; }
  .pb7-t { padding-bottom: $spacing-extra-extra-extra-large; }

  .pt0-t  {  padding-top: $spacing-none; }
  .pt1-t {  padding-top: $spacing-extra-small; }
  .pt2-t  {  padding-top: $spacing-small; }
  .pt3-t  {  padding-top: $spacing-medium; }
  .pt4-t  {  padding-top: $spacing-large; }
  .pt5-t  {  padding-top: $spacing-extra-large; }
  .pt6-t {  padding-top: $spacing-extra-extra-large; }
  .pt7-t { padding-top: $spacing-extra-extra-extra-large; }

  .pv0-t {
    padding-top: $spacing-none;
    padding-bottom: $spacing-none;
  }
  .pv1-t {
    padding-top: $spacing-extra-small;
    padding-bottom: $spacing-extra-small;
  }
  .pv2-t {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }
  .pv3-t {
    padding-top: $spacing-medium;
    padding-bottom: $spacing-medium;
  }
  .pv4-t {
    padding-top: $spacing-large;
    padding-bottom: $spacing-large;
  }
  .pv5-t {
    padding-top: $spacing-extra-large;
    padding-bottom: $spacing-extra-large;
  }
  .pv6-t {
    padding-top: $spacing-extra-extra-large;
    padding-bottom: $spacing-extra-extra-large;
  }
  .pv7-t {
    padding-top: $spacing-extra-extra-extra-large;
    padding-bottom: $spacing-extra-extra-extra-large;
  }
  .ph0-t {
    padding-left: $spacing-none;
    padding-right: $spacing-none;
  }
  .ph1-t {
    padding-left: $spacing-extra-small;
    padding-right: $spacing-extra-small;
  }
  .ph2-t {
    padding-left: $spacing-small;
    padding-right: $spacing-small;
  }
  .ph3-t {
    padding-left: $spacing-medium;
    padding-right: $spacing-medium;
  }
  .ph4-t {
    padding-left: $spacing-large;
    padding-right: $spacing-large;
  }
  .ph5-t {
    padding-left: $spacing-extra-large;
    padding-right: $spacing-extra-large;
  }
  .ph6-t {
    padding-left: $spacing-extra-extra-large;
    padding-right: $spacing-extra-extra-large;
  }
  .ph7-t {
    padding-left: $spacing-extra-extra-extra-large;
    padding-right: $spacing-extra-extra-extra-large;
  }

  .ml-auto-t { margin-left: $spacing-auto; }
  .mr-auto-t { margin-right: $spacing-auto; }

  .ma0-t  {  margin: $spacing-none; }
  .ma1-t {  margin: $spacing-extra-small; }
  .ma2-t  {  margin: $spacing-small; }
  .ma3-t  {  margin: $spacing-medium; }
  .ma4-t  {  margin: $spacing-large; }
  .ma5-t  {  margin: $spacing-extra-large; }
  .ma6-t {  margin: $spacing-extra-extra-large; }
  .ma7-t { margin: $spacing-extra-extra-extra-large; }

  .ml0-t  {  margin-left: $spacing-none; }
  .ml1-t {  margin-left: $spacing-extra-small; }
  .ml2-t  {  margin-left: $spacing-small; }
  .ml3-t  {  margin-left: $spacing-medium; }
  .ml4-t  {  margin-left: $spacing-large; }
  .ml5-t  {  margin-left: $spacing-extra-large; }
  .ml6-t {  margin-left: $spacing-extra-extra-large; }
  .ml7-t { margin-left: $spacing-extra-extra-extra-large; }

  .mr0-t  {  margin-right: $spacing-none; }
  .mr1-t {  margin-right: $spacing-extra-small; }
  .mr2-t  {  margin-right: $spacing-small; }
  .mr3-t  {  margin-right: $spacing-medium; }
  .mr4-t  {  margin-right: $spacing-large; }
  .mr5-t  {  margin-right: $spacing-extra-large; }
  .mr6-t {  margin-right: $spacing-extra-extra-large; }
  .mr7-t { margin-right: $spacing-extra-extra-extra-large; }

  .mb0-t  {  margin-bottom: $spacing-none; }
  .mb1-t {  margin-bottom: $spacing-extra-small; }
  .mb2-t  {  margin-bottom: $spacing-small; }
  .mb3-t  {  margin-bottom: $spacing-medium; }
  .mb4-t  {  margin-bottom: $spacing-large; }
  .mb5-t  {  margin-bottom: $spacing-extra-large; }
  .mb6-t {  margin-bottom: $spacing-extra-extra-large; }
  .mb7-t { margin-bottom: $spacing-extra-extra-extra-large; }

  .mt0-t  {  margin-top: $spacing-none; }
  .mt1-t {  margin-top: $spacing-extra-small; }
  .mt2-t  {  margin-top: $spacing-small; }
  .mt3-t  {  margin-top: $spacing-medium; }
  .mt4-t  {  margin-top: $spacing-large; }
  .mt5-t  {  margin-top: $spacing-extra-large; }
  .mt6-t {  margin-top: $spacing-extra-extra-large; }
  .mt7-t { margin-top: $spacing-extra-extra-extra-large; }

  .mv0-t   {
    margin-top: $spacing-none;
    margin-bottom: $spacing-none;
  }
  .mv1-t  {
    margin-top: $spacing-extra-small;
    margin-bottom: $spacing-extra-small;
  }
  .mv2-t   {
    margin-top: $spacing-small;
    margin-bottom: $spacing-small;
  }
  .mv3-t   {
    margin-top: $spacing-medium;
    margin-bottom: $spacing-medium;
  }
  .mv4-t   {
    margin-top: $spacing-large;
    margin-bottom: $spacing-large;
  }
  .mv5-t   {
    margin-top: $spacing-extra-large;
    margin-bottom: $spacing-extra-large;
  }
  .mv6-t  {
    margin-top: $spacing-extra-extra-large;
    margin-bottom: $spacing-extra-extra-large;
  }
  .mv7-t  {
    margin-top: $spacing-extra-extra-extra-large;
    margin-bottom: $spacing-extra-extra-extra-large;
  }

  .mh0-t   {
    margin-left: $spacing-none;
    margin-right: $spacing-none;
  }
  .mh1-t   {
    margin-left: $spacing-extra-small;
    margin-right: $spacing-extra-small;
  }
  .mh2-t   {
    margin-left: $spacing-small;
    margin-right: $spacing-small;
  }
  .mh3-t   {
    margin-left: $spacing-medium;
    margin-right: $spacing-medium;
  }
  .mh4-t   {
    margin-left: $spacing-large;
    margin-right: $spacing-large;
  }
  .mh5-t   {
    margin-left: $spacing-extra-large;
    margin-right: $spacing-extra-large;
  }
  .mh6-t  {
    margin-left: $spacing-extra-extra-large;
    margin-right: $spacing-extra-extra-large;
  }
  .mh7-t  {
    margin-left: $spacing-extra-extra-extra-large;
    margin-right: $spacing-extra-extra-extra-large;
  }

}

@media #{$breakpoint-medium} {
  .pa0-m  {  padding: $spacing-none; }
  .pa1-m {  padding: $spacing-extra-small; }
  .pa2-m  {  padding: $spacing-small; }
  .pa3-m  {  padding: $spacing-medium; }
  .pa4-m  {  padding: $spacing-large; }
  .pa5-m  {  padding: $spacing-extra-large; }
  .pa6-m {  padding: $spacing-extra-extra-large; }
  .pa7-m { padding: $spacing-extra-extra-extra-large; }

  .pl0-m  {  padding-left: $spacing-none; }
  .pl1-m {  padding-left: $spacing-extra-small; }
  .pl2-m  {  padding-left: $spacing-small; }
  .pl3-m  {  padding-left: $spacing-medium; }
  .pl4-m  {  padding-left: $spacing-large; }
  .pl5-m  {  padding-left: $spacing-extra-large; }
  .pl6-m {  padding-left: $spacing-extra-extra-large; }
  .pl7-m { padding-left: $spacing-extra-extra-extra-large; }

  .pr0-m  {  padding-right: $spacing-none; }
  .pr1-m {  padding-right: $spacing-extra-small; }
  .pr2-m  {  padding-right: $spacing-small; }
  .pr3-m  {  padding-right: $spacing-medium; }
  .pr4-m  {  padding-right: $spacing-large; }
  .pr5-m  {  padding-right: $spacing-extra-large; }
  .pr6-m {  padding-right: $spacing-extra-extra-large; }
  .pr7-m { padding-right: $spacing-extra-extra-extra-large; }

  .pb0-m  {  padding-bottom: $spacing-none; }
  .pb1-m {  padding-bottom: $spacing-extra-small; }
  .pb2-m  {  padding-bottom: $spacing-small; }
  .pb3-m  {  padding-bottom: $spacing-medium; }
  .pb4-m  {  padding-bottom: $spacing-large; }
  .pb5-m  {  padding-bottom: $spacing-extra-large; }
  .pb6-m {  padding-bottom: $spacing-extra-extra-large; }
  .pb7-m { padding-bottom: $spacing-extra-extra-extra-large; }

  .pt0-m  {  padding-top: $spacing-none; }
  .pt1-m {  padding-top: $spacing-extra-small; }
  .pt2-m  {  padding-top: $spacing-small; }
  .pt3-m  {  padding-top: $spacing-medium; }
  .pt4-m  {  padding-top: $spacing-large; }
  .pt5-m  {  padding-top: $spacing-extra-large; }
  .pt6-m {  padding-top: $spacing-extra-extra-large; }
  .pt7-m { padding-top: $spacing-extra-extra-extra-large; }

  .pv0-m {
    padding-top: $spacing-none;
    padding-bottom: $spacing-none;
  }
  .pv1-m {
    padding-top: $spacing-extra-small;
    padding-bottom: $spacing-extra-small;
  }
  .pv2-m {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }
  .pv3-m {
    padding-top: $spacing-medium;
    padding-bottom: $spacing-medium;
  }
  .pv4-m {
    padding-top: $spacing-large;
    padding-bottom: $spacing-large;
  }
  .pv5-m {
    padding-top: $spacing-extra-large;
    padding-bottom: $spacing-extra-large;
  }
  .pv6-m {
    padding-top: $spacing-extra-extra-large;
    padding-bottom: $spacing-extra-extra-large;
  }
  .pv7-m {
    padding-top: $spacing-extra-extra-extra-large;
    padding-bottom: $spacing-extra-extra-extra-large;
  }

  .ph0-m {
    padding-left: $spacing-none;
    padding-right: $spacing-none;
  }
  .ph1-m {
    padding-left: $spacing-extra-small;
    padding-right: $spacing-extra-small;
  }
  .ph2-m {
    padding-left: $spacing-small;
    padding-right: $spacing-small;
  }
  .ph3-m {
    padding-left: $spacing-medium;
    padding-right: $spacing-medium;
  }
  .ph4-m {
    padding-left: $spacing-large;
    padding-right: $spacing-large;
  }
  .ph5-m {
    padding-left: $spacing-extra-large;
    padding-right: $spacing-extra-large;
  }
  .ph6-m {
    padding-left: $spacing-extra-extra-large;
    padding-right: $spacing-extra-extra-large;
  }
  .ph7-m {
    padding-left: $spacing-extra-extra-extra-large;
    padding-right: $spacing-extra-extra-extra-large;
  }

  .ml-auto-m { margin-left: $spacing-auto; }
  .mr-auto-m { margin-right: $spacing-auto; }

  .ma0-m  {  margin: $spacing-none; }
  .ma1-m {  margin: $spacing-extra-small; }
  .ma2-m  {  margin: $spacing-small; }
  .ma3-m  {  margin: $spacing-medium; }
  .ma4-m  {  margin: $spacing-large; }
  .ma5-m  {  margin: $spacing-extra-large; }
  .ma6-m {  margin: $spacing-extra-extra-large; }
  .ma7-m { margin: $spacing-extra-extra-extra-large; }

  .ml0-m  {  margin-left: $spacing-none; }
  .ml1-m {  margin-left: $spacing-extra-small; }
  .ml2-m  {  margin-left: $spacing-small; }
  .ml3-m  {  margin-left: $spacing-medium; }
  .ml4-m  {  margin-left: $spacing-large; }
  .ml5-m  {  margin-left: $spacing-extra-large; }
  .ml6-m {  margin-left: $spacing-extra-extra-large; }
  .ml7-m { margin-left: $spacing-extra-extra-extra-large; }

  .mr0-m  {  margin-right: $spacing-none; }
  .mr1-m {  margin-right: $spacing-extra-small; }
  .mr2-m  {  margin-right: $spacing-small; }
  .mr3-m  {  margin-right: $spacing-medium; }
  .mr4-m  {  margin-right: $spacing-large; }
  .mr5-m  {  margin-right: $spacing-extra-large; }
  .mr6-m {  margin-right: $spacing-extra-extra-large; }
  .mr7-m { margin-right: $spacing-extra-extra-extra-large; }

  .mb0-m  {  margin-bottom: $spacing-none; }
  .mb1-m {  margin-bottom: $spacing-extra-small; }
  .mb2-m  {  margin-bottom: $spacing-small; }
  .mb3-m  {  margin-bottom: $spacing-medium; }
  .mb4-m  {  margin-bottom: $spacing-large; }
  .mb5-m  {  margin-bottom: $spacing-extra-large; }
  .mb6-m {  margin-bottom: $spacing-extra-extra-large; }
  .mb7-m { margin-bottom: $spacing-extra-extra-extra-large; }

  .mt0-m  {  margin-top: $spacing-none; }
  .mt1-m {  margin-top: $spacing-extra-small; }
  .mt2-m  {  margin-top: $spacing-small; }
  .mt3-m  {  margin-top: $spacing-medium; }
  .mt4-m  {  margin-top: $spacing-large; }
  .mt5-m  {  margin-top: $spacing-extra-large; }
  .mt6-m {  margin-top: $spacing-extra-extra-large; }
  .mt7-m { margin-top: $spacing-extra-extra-extra-large; }

  .mv0-m {
    margin-top: $spacing-none;
    margin-bottom: $spacing-none;
  }
  .mv1-m {
    margin-top: $spacing-extra-small;
    margin-bottom: $spacing-extra-small;
  }
  .mv2-m {
    margin-top: $spacing-small;
    margin-bottom: $spacing-small;
  }
  .mv3-m {
    margin-top: $spacing-medium;
    margin-bottom: $spacing-medium;
  }
  .mv4-m {
    margin-top: $spacing-large;
    margin-bottom: $spacing-large;
  }
  .mv5-m {
    margin-top: $spacing-extra-large;
    margin-bottom: $spacing-extra-large;
  }
  .mv6-m {
    margin-top: $spacing-extra-extra-large;
    margin-bottom: $spacing-extra-extra-large;
  }
  .mv7-m {
    margin-top: $spacing-extra-extra-extra-large;
    margin-bottom: $spacing-extra-extra-extra-large;
  }

  .mh0-m {
    margin-left: $spacing-none;
    margin-right: $spacing-none;
  }
  .mh1-m {
    margin-left: $spacing-extra-small;
    margin-right: $spacing-extra-small;
  }
  .mh2-m {
    margin-left: $spacing-small;
    margin-right: $spacing-small;
  }
  .mh3-m {
    margin-left: $spacing-medium;
    margin-right: $spacing-medium;
  }
  .mh4-m {
    margin-left: $spacing-large;
    margin-right: $spacing-large;
  }
  .mh5-m {
    margin-left: $spacing-extra-large;
    margin-right: $spacing-extra-large;
  }
  .mh6-m {
    margin-left: $spacing-extra-extra-large;
    margin-right: $spacing-extra-extra-large;
  }
  .mh7-m {
    margin-left: $spacing-extra-extra-extra-large;
    margin-right: $spacing-extra-extra-extra-large;
  }

}

@media #{$breakpoint-large} {
  .pa0-l  {  padding: $spacing-none; }
  .pa1-l {  padding: $spacing-extra-small; }
  .pa2-l  {  padding: $spacing-small; }
  .pa3-l  {  padding: $spacing-medium; }
  .pa4-l  {  padding: $spacing-large; }
  .pa5-l  {  padding: $spacing-extra-large; }
  .pa6-l {  padding: $spacing-extra-extra-large; }
  .pa7-l { padding: $spacing-extra-extra-extra-large; }

  .pl0-l  {  padding-left: $spacing-none; }
  .pl1-l {  padding-left: $spacing-extra-small; }
  .pl2-l  {  padding-left: $spacing-small; }
  .pl3-l  {  padding-left: $spacing-medium; }
  .pl4-l  {  padding-left: $spacing-large; }
  .pl5-l  {  padding-left: $spacing-extra-large; }
  .pl6-l {  padding-left: $spacing-extra-extra-large; }
  .pl7-l { padding-left: $spacing-extra-extra-extra-large; }

  .pr0-l  {  padding-right: $spacing-none; }
  .pr1-l {  padding-right: $spacing-extra-small; }
  .pr2-l  {  padding-right: $spacing-small; }
  .pr3-l  {  padding-right: $spacing-medium; }
  .pr4-l  {  padding-right: $spacing-large; }
  .pr5-l  {  padding-right: $spacing-extra-large; }
  .pr6-l {  padding-right: $spacing-extra-extra-large; }
  .pr7-l { padding-right: $spacing-extra-extra-extra-large; }

  .pb0-l  {  padding-bottom: $spacing-none; }
  .pb1-l {  padding-bottom: $spacing-extra-small; }
  .pb2-l  {  padding-bottom: $spacing-small; }
  .pb3-l  {  padding-bottom: $spacing-medium; }
  .pb4-l  {  padding-bottom: $spacing-large; }
  .pb5-l  {  padding-bottom: $spacing-extra-large; }
  .pb6-l {  padding-bottom: $spacing-extra-extra-large; }
  .pb7-l { padding-bottom: $spacing-extra-extra-extra-large; }

  .pt0-l  {  padding-top: $spacing-none; }
  .pt1-l {  padding-top: $spacing-extra-small; }
  .pt2-l  {  padding-top: $spacing-small; }
  .pt3-l  {  padding-top: $spacing-medium; }
  .pt4-l  {  padding-top: $spacing-large; }
  .pt5-l  {  padding-top: $spacing-extra-large; }
  .pt6-l {  padding-top: $spacing-extra-extra-large; }
  .pt7-l { padding-top: $spacing-extra-extra-extra-large; }

  .pv0-l {
    padding-top: $spacing-none;
    padding-bottom: $spacing-none;
  }
  .pv1-l {
    padding-top: $spacing-extra-small;
    padding-bottom: $spacing-extra-small;
  }
  .pv2-l {
    padding-top: $spacing-small;
    padding-bottom: $spacing-small;
  }
  .pv3-l {
    padding-top: $spacing-medium;
    padding-bottom: $spacing-medium;
  }
  .pv4-l {
    padding-top: $spacing-large;
    padding-bottom: $spacing-large;
  }
  .pv5-l {
    padding-top: $spacing-extra-large;
    padding-bottom: $spacing-extra-large;
  }
  .pv6-l {
    padding-top: $spacing-extra-extra-large;
    padding-bottom: $spacing-extra-extra-large;
  }
  .pv7-l {
    padding-top: $spacing-extra-extra-extra-large;
    padding-bottom: $spacing-extra-extra-extra-large;
  }

  .ph0-l {
    padding-left: $spacing-none;
    padding-right: $spacing-none;
  }
  .ph1-l {
    padding-left: $spacing-extra-small;
    padding-right: $spacing-extra-small;
  }
  .ph2-l {
    padding-left: $spacing-small;
    padding-right: $spacing-small;
  }
  .ph3-l {
    padding-left: $spacing-medium;
    padding-right: $spacing-medium;
  }
  .ph4-l {
    padding-left: $spacing-large;
    padding-right: $spacing-large;
  }
  .ph5-l {
    padding-left: $spacing-extra-large;
    padding-right: $spacing-extra-large;
  }
  .ph6-l {
    padding-left: $spacing-extra-extra-large;
    padding-right: $spacing-extra-extra-large;
  }
  .ph7-l {
    padding-left: $spacing-extra-extra-extra-large;
    padding-right: $spacing-extra-extra-extra-large;
  }

  .ml-auto-l { margin-left: $spacing-auto; }
  .mr-auto-l { margin-right: $spacing-auto; }

  .ma0-l  {  margin: $spacing-none; }
  .ma1-l {  margin: $spacing-extra-small; }
  .ma2-l  {  margin: $spacing-small; }
  .ma3-l  {  margin: $spacing-medium; }
  .ma4-l  {  margin: $spacing-large; }
  .ma5-l  {  margin: $spacing-extra-large; }
  .ma6-l {  margin: $spacing-extra-extra-large; }
  .ma7-l { margin: $spacing-extra-extra-extra-large; }

  .ml0-l  {  margin-left: $spacing-none; }
  .ml1-l {  margin-left: $spacing-extra-small; }
  .ml2-l  {  margin-left: $spacing-small; }
  .ml3-l  {  margin-left: $spacing-medium; }
  .ml4-l  {  margin-left: $spacing-large; }
  .ml5-l  {  margin-left: $spacing-extra-large; }
  .ml6-l {  margin-left: $spacing-extra-extra-large; }
  .ml7-l { margin-left: $spacing-extra-extra-extra-large; }

  .mr0-l  {  margin-right: $spacing-none; }
  .mr1-l {  margin-right: $spacing-extra-small; }
  .mr2-l  {  margin-right: $spacing-small; }
  .mr3-l  {  margin-right: $spacing-medium; }
  .mr4-l  {  margin-right: $spacing-large; }
  .mr5-l  {  margin-right: $spacing-extra-large; }
  .mr6-l {  margin-right: $spacing-extra-extra-large; }
  .mr7-l { margin-right: $spacing-extra-extra-extra-large; }

  .mb0-l  {  margin-bottom: $spacing-none; }
  .mb1-l {  margin-bottom: $spacing-extra-small; }
  .mb2-l  {  margin-bottom: $spacing-small; }
  .mb3-l  {  margin-bottom: $spacing-medium; }
  .mb4-l  {  margin-bottom: $spacing-large; }
  .mb5-l  {  margin-bottom: $spacing-extra-large; }
  .mb6-l {  margin-bottom: $spacing-extra-extra-large; }
  .mb7-l { margin-bottom: $spacing-extra-extra-extra-large; }

  .mt0-l  {  margin-top: $spacing-none; }
  .mt1-l {  margin-top: $spacing-extra-small; }
  .mt2-l  {  margin-top: $spacing-small; }
  .mt3-l  {  margin-top: $spacing-medium; }
  .mt4-l  {  margin-top: $spacing-large; }
  .mt5-l  {  margin-top: $spacing-extra-large; }
  .mt6-l {  margin-top: $spacing-extra-extra-large; }
  .mt7-l { margin-top: $spacing-extra-extra-extra-large; }

  .mv0-l {
    margin-top: $spacing-none;
    margin-bottom: $spacing-none;
  }
  .mv1-l {
    margin-top: $spacing-extra-small;
    margin-bottom: $spacing-extra-small;
  }
  .mv2-l {
    margin-top: $spacing-small;
    margin-bottom: $spacing-small;
  }
  .mv3-l {
    margin-top: $spacing-medium;
    margin-bottom: $spacing-medium;
  }
  .mv4-l {
    margin-top: $spacing-large;
    margin-bottom: $spacing-large;
  }
  .mv5-l {
    margin-top: $spacing-extra-large;
    margin-bottom: $spacing-extra-large;
  }
  .mv6-l {
    margin-top: $spacing-extra-extra-large;
    margin-bottom: $spacing-extra-extra-large;
  }
  .mv7-l {
    margin-top: $spacing-extra-extra-extra-large;
    margin-bottom: $spacing-extra-extra-extra-large;
  }

  .mh0-l {
    margin-left: $spacing-none;
    margin-right: $spacing-none;
  }
  .mh1-l {
    margin-left: $spacing-extra-small;
    margin-right: $spacing-extra-small;
  }
  .mh2-l {
    margin-left: $spacing-small;
    margin-right: $spacing-small;
  }
  .mh3-l {
    margin-left: $spacing-medium;
    margin-right: $spacing-medium;
  }
  .mh4-l {
    margin-left: $spacing-large;
    margin-right: $spacing-large;
  }
  .mh5-l {
    margin-left: $spacing-extra-large;
    margin-right: $spacing-extra-large;
  }
  .mh6-l {
    margin-left: $spacing-extra-extra-large;
    margin-right: $spacing-extra-extra-large;
  }
  .mh7-l {
    margin-left: $spacing-extra-extra-extra-large;
    margin-right: $spacing-extra-extra-extra-large;
  }
}
