
// Converted Variables


// Custom Media Query Variables


/*

   COORDINATES
   Docs: http://tachyons.io/docs/layout/position/

   Use in combination with the position module.

   Base:
     top
     bottom
     right
     left

   Modifiers:
     -0  = literal value 0
     -1  = literal value 1
     -2  = literal value 2
     --1 = literal value -1
     --2 = literal value -2

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

.top-auto    { top:    auto; }
.right-auto  { right:  auto; }
.bottom-auto { bottom: auto; }
.left-auto   { left:   auto; }

.top-0    { top:    0; }
.right-0  { right:  0; }
.bottom-0 { bottom: 0; }
.left-0   { left:   0; }

.top-1    { top:    1rem; }
.right-1  { right:  1rem; }
.bottom-1 { bottom: 1rem; }
.left-1   { left:   1rem; }

.top-2    { top:    2rem; }
.right-2  { right:  2rem; }
.bottom-2 { bottom: 2rem; }
.left-2   { left:   2rem; }

.top-3    { top:    3rem; }
.right-3  { right:  3rem; }
.bottom-3 { bottom: 3rem; }
.left-3   { left:   3rem; }

.top-4    { top:    4rem; }
.right-4  { right:  4rem; }
.bottom-4 { bottom: 4rem; }
.left-4   { left:   4rem; }

.top-5    { top:    5rem; }
.right-5  { right:  5rem; }
.bottom-5 { bottom: 5rem; }
.left-5   { left:   5rem; }

.top--1    { top:    -1rem; }
.right--1  { right:  -1rem; }
.bottom--1 { bottom: -1rem; }
.left--1   { left:   -1rem; }

.top--2    { top:    -2rem; }
.right--2  { right:  -2rem; }
.bottom--2 { bottom: -2rem; }
.left--2   { left:   -2rem; }

.absolute--fill {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media #{$breakpoint-not-small} {
  .top-auto-ns    { top:    auto; }
  .right-auto-ns  { right:  auto; }
  .bottom-auto-ns { bottom: auto; }
  .left-auto-ns   { left:   auto; }
  .top-0-ns     { top:   0; }
  .left-0-ns    { left:  0; }
  .right-0-ns   { right: 0; }
  .bottom-0-ns  { bottom: 0; }
  .top-1-ns     { top:   1rem; }
  .left-1-ns    { left:  1rem; }
  .right-1-ns   { right: 1rem; }
  .bottom-1-ns  { bottom: 1rem; }
  .top-2-ns     { top:   2rem; }
  .left-2-ns    { left:  2rem; }
  .right-2-ns   { right: 2rem; }
  .bottom-2-ns  { bottom: 2rem; }
  .top--1-ns    { top:    -1rem; }
  .right--1-ns  { right:  -1rem; }
  .bottom--1-ns { bottom: -1rem; }
  .left--1-ns   { left:   -1rem; }
  .top--2-ns    { top:    -2rem; }
  .right--2-ns  { right:  -2rem; }
  .bottom--2-ns { bottom: -2rem; }
  .left--2-ns   { left:   -2rem; }
  .absolute--fill-ns {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media #{$breakpoint-tablet} {
  .top-auto-t    { top:    auto; }
  .right-auto-t  { right:  auto; }
  .bottom-auto-t { bottom: auto; }
  .left-auto-t   { left:   auto; }
  .top-0-t     { top:   0; }
  .left-0-t    { left:  0; }
  .right-0-t   { right: 0; }
  .bottom-0-t  { bottom: 0; }
  .top-1-t     { top:   1rem; }
  .left-1-t    { left:  1rem; }
  .right-1-t   { right: 1rem; }
  .bottom-1-t  { bottom: 1rem; }
  .top-2-t     { top:   2rem; }
  .left-2-t    { left:  2rem; }
  .right-2-t   { right: 2rem; }
  .bottom-2-t  { bottom: 2rem; }
  .top--1-t    { top:    -1rem; }
  .right--1-t  { right:  -1rem; }
  .bottom--1-t { bottom: -1rem; }
  .left--1-t   { left:   -1rem; }
  .top--2-t    { top:    -2rem; }
  .right--2-t  { right:  -2rem; }
  .bottom--2-t { bottom: -2rem; }
  .left--2-t   { left:   -2rem; }
  .absolute--fill-t {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media #{$breakpoint-medium} {
  .top-auto-m    { top:    auto; }
  .right-auto-m  { right:  auto; }
  .bottom-auto-m { bottom: auto; }
  .left-auto-m   { left:   auto; }
  .top-0-m     { top:   0; }
  .left-0-m    { left:  0; }
  .right-0-m   { right: 0; }
  .bottom-0-m  { bottom: 0; }
  .top-1-m     { top:   1rem; }
  .left-1-m    { left:  1rem; }
  .right-1-m   { right: 1rem; }
  .bottom-1-m  { bottom: 1rem; }
  .top-2-m     { top:   2rem; }
  .left-2-m    { left:  2rem; }
  .right-2-m   { right: 2rem; }
  .bottom-2-m  { bottom: 2rem; }
  .top--1-m    { top:    -1rem; }
  .right--1-m  { right:  -1rem; }
  .bottom--1-m { bottom: -1rem; }
  .left--1-m   { left:   -1rem; }
  .top--2-m    { top:    -2rem; }
  .right--2-m  { right:  -2rem; }
  .bottom--2-m { bottom: -2rem; }
  .left--2-m   { left:   -2rem; }
  .absolute--fill-m {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media #{$breakpoint-large} {
  .top-auto-l    { top:    auto; }
  .right-auto-l  { right:  auto; }
  .bottom-auto-l { bottom: auto; }
  .left-auto-l   { left:   auto; }
  .top-0-l     { top:   0; }
  .left-0-l    { left:  0; }
  .right-0-l   { right: 0; }
  .bottom-0-l  { bottom: 0; }
  .top-1-l     { top:   1rem; }
  .left-1-l    { left:  1rem; }
  .right-1-l   { right: 1rem; }
  .bottom-1-l  { bottom: 1rem; }
  .top-2-l     { top:   2rem; }
  .left-2-l    { left:  2rem; }
  .right-2-l   { right: 2rem; }
  .bottom-2-l  { bottom: 2rem; }
  .top--1-l    { top:    -1rem; }
  .right--1-l  { right:  -1rem; }
  .bottom--1-l { bottom: -1rem; }
  .left--1-l   { left:   -1rem; }
  .top--2-l    { top:    -2rem; }
  .right--2-l  { right:  -2rem; }
  .bottom--2-l { bottom: -2rem; }
  .left--2-l   { left:   -2rem; }
  .absolute--fill-l {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
