
// Converted Variables


// Custom Media Query Variables


/*

   HEIGHTS
   Docs: http://tachyons.io/docs/layout/heights/

   Base:
     h = height
     min-h = min-height
     min-vh = min-height vertical screen height
     vh = vertical screen height

   Modifiers
     1 = 1st step in height scale
     2 = 2nd step in height scale
     3 = 3rd step in height scale
     4 = 4th step in height scale
     5 = 5th step in height scale

     -25   = literal value 25%
     -50   = literal value 50%
     -75   = literal value 75%
     -100  = literal value 100%

     -auto = string value of auto
     -inherit = string value of inherit

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

/* Height Scale */

.h1 { height: $height-1; }
.h2 { height: $height-2; }
.h3 { height: $height-3; }
.h4 { height: $height-4; }
.h5 { height: $height-5; }

/* Height Percentages - Based off of height of parent */

.h-25 {  height:  25%; }
.h-50 {  height:  50%; }
.h-75 {  height:  75%; }
.h-100 { height: 100%; }

.min-h-100 { min-height: 100%; }

/* Screen Height Percentage */

.vh-25 {  height:  25vh; }
.vh-50 {  height:  50vh; }
.vh-75 {  height:  75vh; }
.vh-100 { height: 100vh; }

.min-vh-100 { min-height: 100vh; }


/* String Properties */

.h-auto {     height: auto; }
.h-inherit {  height: inherit; }

@media #{$breakpoint-not-small} {
  .h1-ns {  height: $height-1; }
  .h2-ns {  height: $height-2; }
  .h3-ns {  height: $height-3; }
  .h4-ns {  height: $height-4; }
  .h5-ns {  height: $height-5; }
  .h-25-ns { height: 25%; }
  .h-50-ns { height: 50%; }
  .h-75-ns { height: 75%; }
  .h-100-ns { height: 100%; }
  .min-h-100-ns { min-height: 100%; }
  .vh-25-ns {  height:  25vh; }
  .vh-50-ns {  height:  50vh; }
  .vh-75-ns {  height:  75vh; }
  .vh-100-ns { height: 100vh; }
  .min-vh-100-ns { min-height: 100vh; }
  .h-auto-ns { height: auto; }
  .h-inherit-ns { height: inherit; }
}

@media #{$breakpoint-tablet} {
  .h1-t {  height: $height-1; }
  .h2-t {  height: $height-2; }
  .h3-t {  height: $height-3; }
  .h4-t {  height: $height-4; }
  .h5-t {  height: $height-5; }
  .h-25-t { height: 25%; }
  .h-50-t { height: 50%; }
  .h-75-t { height: 75%; }
  .h-100-t { height: 100%; }
  .min-h-100-t { min-height: 100%; }
  .vh-25-t {  height:  25vh; }
  .vh-50-t {  height:  50vh; }
  .vh-75-t {  height:  75vh; }
  .vh-100-t { height: 100vh; }
  .min-vh-100-t { min-height: 100vh; }
  .h-auto-t { height: auto; }
  .h-inherit-t { height: inherit; }
}

@media #{$breakpoint-medium} {
  .h1-m { height: $height-1; }
  .h2-m { height: $height-2; }
  .h3-m { height: $height-3; }
  .h4-m { height: $height-4; }
  .h5-m { height: $height-5; }
  .h-25-m { height: 25%; }
  .h-50-m { height: 50%; }
  .h-75-m { height: 75%; }
  .h-100-m { height: 100%; }
  .min-h-100-m { min-height: 100%; }
  .vh-25-m {  height:  25vh; }
  .vh-50-m {  height:  50vh; }
  .vh-75-m {  height:  75vh; }
  .vh-100-m { height: 100vh; }
  .min-vh-100-m { min-height: 100vh; }
  .h-auto-m { height: auto; }
  .h-inherit-m { height: inherit; }
}

@media #{$breakpoint-large} {
  .h1-l { height: $height-1; }
  .h2-l { height: $height-2; }
  .h3-l { height: $height-3; }
  .h4-l { height: $height-4; }
  .h5-l { height: $height-5; }
  .h-25-l { height: 25%; }
  .h-50-l { height: 50%; }
  .h-75-l { height: 75%; }
  .h-100-l { height: 100%; }
  .min-h-100-l { min-height: 100%; }
  .vh-25-l {  height:  25vh; }
  .vh-50-l {  height:  50vh; }
  .vh-75-l {  height:  75vh; }
  .vh-100-l { height: 100vh; }
  .min-vh-100-l { min-height: 100vh; }
  .h-auto-l { height: auto; }
  .h-inherit-l { height: inherit; }
}
