.highlight_content {

	position: relative;
	margin-top: 20px;
	padding: 30px 20px 20px;
	background-color: $brand-secondary;
	color: $white; 

	h1, h2, h3, h4, h5, h6 {

		margin-top: 0;
		color: $white;

	}

	&:before {

	    @include corner-top-right(45px, $white, $brand-primary);

	}

}