
// generate classes :
@include gridle_generate_classes ( );

.container {
    @include gridle_container();
    max-width: 1200px;
    margin: 0 auto;
}

// universal mixin
@include gridle(container);