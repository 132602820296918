/* =============================================================================
	variables
	========================================================================== */

$grid-gutter-width: 20px;

/* =============================================================================
	Colors
	========================================================================== */

$black:										#000 !default;
$grey-darker:								#7c7c7c !default;
$grey-dark:									#9d9d9c !default;
$grey:										#dadada !default;
$grey-light:								#f5f5f5 !default;
$grey-lighter:								lighten($black, 93.5%) !default; // #eee
$white:										#fff !default;

$brand-primary:								#009fe3 !default;
$brand-secondary:							#292930 !default;

$brand-success:								#5cb85c !default;
$brand-info:								#5bc0de !default;
$brand-warning:								#f0ad4e !default;
$brand-danger:								#d9534f !default;

/* =============================================================================
	Scaffolding
	========================================================================== */

$body-bg:									#fff !default;
$text-color:								#7c7c7c !default;
$text-muted:								$grey-light !default;
$hr-border:									#ebebeb !default;

/* =============================================================================
	Typography
	========================================================================== */

$font-family-sans-serif:					"proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:							Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:						Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:							$font-family-sans-serif !default;
$font-family-weight:						400 !default;

$font-size-base:							16px !default;
$font-size-large:							floor(($font-size-base * 1.125)) !default; // ~18px
$font-size-small:							ceil(($font-size-base * 0.85)) !default; // ~14px

$font-size-h1:								ceil(($font-size-base * 2.8)) !default; // ~45px
$font-size-h2:								floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:								ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:								ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:								$font-size-base !default;
$font-size-h6:								ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-small-h1:						floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-small-h2:						ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-small-h3:						ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small-h4:						$font-size-base !default;
$font-size-small-h5:						ceil(($font-size-base * 0.85)) !default; // ~12px
$font-size-small-h6:						ceil(($font-size-base * 0.85)) !default; // ~12px

$line-height-base:							2 !default; // 32/16
$line-height-computed:						floor(($font-size-base * $line-height-base)) !default; // ~20px
$line-height-large:							1.33 !default;
$line-height-small:							1.5 !default;

$headings-font-family:						inherit !default;
$headings-font-weight:						800 !default;
$headings-line-height:						1.1 !default;
$headings-color:							$brand-secondary !default;
$headings-small-color:						$grey-light !default;

$link-color:								$brand-primary !default;
$link-hover-color:							darken($link-color, 15%) !default;
$link-hover-decoration:						underline !default;

$blockquote-font-size:						$font-size-base !default;

$code-color:								#c7254e !default;
$code-bg:									#f9f2f4 !default;
$ins-color:									$grey-dark !default;
$ins-bg:									#ff9 !default;
$mark-color:								$grey-dark !default;
$mark-bg:									#ff0 !default;
$pre-bg:									#f5f5f5 !default;
$pre-color:									$grey-dark !default;
$pre-border-color:							#ccc !default;
$pre-scrollable-max-height:					340px !default;

/* =============================================================================
	Navigation
	========================================================================== */

$nav-font-family:							inherit !default;
$nav-font-size:								$font-size-base !default;
$nav-font-color:							$brand-secondary !default;
$nav-font-hover-color:						$brand-primary !default;
$nav-font-weight:							700 !default;
$nav-bg-color:								$white !default;
$nav-bg-hover-color:						$grey-lighter !default;

$nav-dd-font-size:							$font-size-base !default;
$nav-dd-font-color:							$brand-secondary  !default;
$nav-dd-font-hover-color:					$brand-primary !default;
$nav-dd-bg-color:							$white !default;
$nav-dd-bg-hover-color:						$body-bg !default;

/* =============================================================================
	Accordion
	========================================================================== */

$accordion-title-font-color:				$white !default;
$accordion-title-bg-color:					$brand-primary !default;
$accordion-title-font-hover-color:			$white !default;
$accordion-title-bg-hover-color:			$brand-secondary !default;
$accordion-title-font-active-color:			$white !default;
$accordion-title-bg-active-color:			$brand-secondary !default;

$accordion-arrow-bg-color:					$brand-primary !default;
$accordion-arrow-bg-hover-color:			$brand-secondary !default;

/* =============================================================================
	Padding and Border Radius
	Values based on 14px text and 1.428 line-height (~20px to start)
	========================================================================== */

$padding-base-vertical:						6px !default;
$padding-base-horizontal:					12px !default;

$padding-large-vertical:					10px !default;
$padding-large-horizontal:					24px !default;

$padding-small-vertical:					5px !default;
$padding-small-horizontal:					10px !default;

$padding-xs-vertical:						1px !default;
$padding-xs-horizontal:						5px !default;

$border-radius-base:						4px !default;
$border-radius-large:						6px !default;
$border-radius-small:						3px !default;

/* =============================================================================
	Tables
	========================================================================== */

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            8px !default;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding:  5px !default;

//** Default background color used for all tables.
$table-bg:                      transparent !default;
//** Background color used for `.table-striped`.
$table-bg-accent:               #f9f9f9 !default;
//** Background color used for `.table-hover`.
$table-bg-hover:                #f5f5f5 !default;
$table-bg-active:               $table-bg-hover !default;

//** Border color for table and cell borders.
$table-border-color:            #ddd !default;

/* =============================================================================
	Buttons
	========================================================================== */

$btn-font-weight:							400 !default;

$btn-brand-color:							#fff !default;
$btn-brand-bg:								$brand-primary !default;
$btn-brand-border:							$brand-primary !default;

$btn-default-color:							#333 !default;
$btn-default-bg:							#fff !default;
$btn-default-border:						#ccc !default;

$btn-white-color:							$brand-secondary !default;
$btn-white-bg:								$white !default;
$btn-white-border:							$white !default;

$btn-grey-color:							$grey-darker !default;
$btn-grey-bg:								$grey-light !default;
$btn-grey-border:							$grey-light !default;

$btn-primary-color:							#fff !default;
$btn-primary-bg:							$brand-primary !default;
$btn-primary-border:						darken($btn-primary-bg, 5%) !default;

$btn-success-color:							#fff !default;
$btn-success-bg:							$brand-success !default;
$btn-success-border:						darken($btn-success-bg, 5%) !default;

$btn-info-color:							#fff !default;
$btn-info-bg:								$brand-info !default;
$btn-info-border:							darken($btn-info-bg, 5%) !default;

$btn-warning-color:							#fff !default;
$btn-warning-bg:							$brand-warning !default;
$btn-warning-border:						darken($btn-warning-bg, 5%) !default;

$btn-danger-color:							#fff !default;
$btn-danger-bg: 							$brand-danger !default;
$btn-danger-border:							darken($btn-danger-bg, 5%) !default;

$btn-link-disabled-color:					$grey-light !default;

/* =============================================================================
	Forms
	========================================================================== */

//** `<input>` background color
$input-bg:									#fff !default;
//** `<input disabled>` background color
$input-bg-disabled:							$grey-lighter !default;

//** Text color for `<input>`s
$input-color:								$grey !default;
//** `<input>` border color
$input-border:								#ccc !default;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
$input-border-radius:						$border-radius-base !default;
//** Large `.form-control` border radius
$input-border-radius-large:					$border-radius-large !default;
//** Small `.form-control` border radius
$input-border-radius-small:					$border-radius-small !default;

//** Border color for inputs on focus
$input-border-focus:						#66afe9 !default;

//** Placeholder text color
$input-color-placeholder:					#999 !default;

//** Default `.form-control` height
$input-height-base:							($line-height-computed + ($padding-base-vertical * 2) + 2) !default;
//** Large `.form-control` height
$input-height-large:						(ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) !default;
//** Small `.form-control` height
$input-height-small:						(floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) !default;

$legend-color:								$grey-dark !default;
$legend-border-color:						#e5e5e5 !default;

//** Background color for textual input addons
$input-group-addon-bg:						$grey-lighter !default;
//** Border color for textual input addons
$input-group-addon-border-color:			$input-border !default;

//** Disabled cursor for form controls and buttons.
$cursor-disabled:							not-allowed !default;

/* =============================================================================
	Form states and alerts
	========================================================================== */

$state-success-text:						#3c763d !default;
$state-success-bg:							#dff0d8 !default;
$state-success-border:						darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:							#31708f !default;
$state-info-bg:								#d9edf7 !default;
$state-info-border:							darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:						#8a6d3b !default;
$state-warning-bg:							#fcf8e3 !default;
$state-warning-border:						darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:							#a94442 !default;
$state-danger-bg:							#f2dede !default;
$state-danger-border:						darken(adjust-hue($state-danger-bg, -10), 5%) !default;

$alert-padding:								15px !default;
$alert-border-radius:						$border-radius-base !default;
$alert-link-font-weight:					bold !default;

$alert-success-bg:							$state-success-bg !default;
$alert-success-text:						$state-success-text !default;
$alert-success-border:						$state-success-border !default;

$alert-info-bg:								$state-info-bg !default;
$alert-info-text:							$state-info-text !default;
$alert-info-border:							$state-info-border !default;

$alert-warning-bg:							$state-warning-bg !default;
$alert-warning-text:						$state-warning-text !default;
$alert-warning-border:						$state-warning-border !default;

$alert-danger-bg:							$state-danger-bg !default;
$alert-danger-text:							$state-danger-text !default;
$alert-danger-border:						$state-danger-border !default;
