
// Converted Variables


// Custom Media Query Variables


/*

   LETTER SPACING
   Docs: http://tachyons.io/docs/typography/tracking/

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

.tracked-none  { letter-spacing: 0; text-indent: 0;}
.tracked       { letter-spacing:  $letter-spacing-1; text-indent: $text-indent-1; }
.tracked-tight { letter-spacing: $letter-spacing-tight; text-indent: $text-indent-tight; }
.tracked-mega  { letter-spacing:  $letter-spacing-2; text-indent: $text-indent-2; }

@media #{$breakpoint-not-small} {
  .tracked-none-ns  { letter-spacing: 0; text-indent: 0; }
  .tracked-ns       { letter-spacing:  $letter-spacing-1; text-indent: $text-indent-1; }
  .tracked-tight-ns { letter-spacing: $letter-spacing-tight; text-indent: $text-indent-tight; }
  .tracked-mega-ns  { letter-spacing:  $letter-spacing-2; text-indent: $text-indent-2; }
}

@media #{$breakpoint-tablet} {
  .tracked-none-t  { letter-spacing: 0; text-indent: 0; }
  .tracked-t       { letter-spacing:  $letter-spacing-1; text-indent: $text-indent-1; }
  .tracked-tight-t { letter-spacing: $letter-spacing-tight; text-indent: $text-indent-tight; }
  .tracked-mega-t  { letter-spacing:  $letter-spacing-2; text-indent: $text-indent-2; }
}

@media #{$breakpoint-medium} {
  .tracked-none-m  { letter-spacing: 0; text-indent: 0; }
  .tracked-m       { letter-spacing:  $letter-spacing-1; text-indent: $text-indent-1; }
  .tracked-tight-m { letter-spacing: $letter-spacing-tight; text-indent: $text-indent-tight; }
  .tracked-mega-m  { letter-spacing:  $letter-spacing-2; text-indent: $text-indent-2; }
}

@media #{$breakpoint-large} {
  .tracked-none-l  { letter-spacing: 0; text-indent: 0; }
  .tracked-l       { letter-spacing:  $letter-spacing-1; text-indent: $text-indent-1; }
  .tracked-tight-l { letter-spacing: $letter-spacing-tight; text-indent: $text-indent-tight; }
  .tracked-mega-l  { letter-spacing:  $letter-spacing-2; text-indent: $text-indent-2; }
}
