.page-header-title {

	position: relative;
	margin-bottom: 20px;
	width: 100%; 
	height: 80px;
	padding-top: 25px;
	padding-left: 140px;
	background: $brand-primary;

	font-size: $font-size-base;
	color: $white;
	font-weight: 800;
	text-transform: uppercase;

	@include gridle_state ( tb md lg ) {

		width: 580px; 
		padding-left: 250px;

	}

}

.page-header {

	position: relative;
	padding: 40px;
	background: $grey-light;
	z-index: 1;

	@include gridle_state ( tb ) {

		padding: 60px;

	}

	@include gridle_state ( md lg ) {

		padding: 60px 100px;

	}

	/* &:before {

		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 140px;
		height: 140px;
		background-size: 140px 140px;
		background-image: url(../images/page-header-title.png);
		background-repeat: no-repeat;
		z-index: 2;

		@include gridle_state ( tb md lg ) {

			width: 230px;
			height: 230px;
			background-size: 230px 230px;

		}

	} */

	h1 {

		font-size: 34px;

		@include gridle_state ( tb md lg ) {

			font-size: 45px;

		}

	}

	.line {

		display: block;
		margin: 40px 0;
		width: 180px;
		height: 4px;
		background-color: $grey;

		@include gridle_state ( tb md lg ) {

			margin: 60px 0;

		}

	}

	.introduction {

		font-size: $font-size-base;
		color: $brand-secondary;
		line-height: 28px;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 2px;

	}

	img {

		margin-bottom: 40px;
		width: 50% !important;
		height: auto !important;

		@include gridle_state ( tb md lg ) {

			margin-bottom: 60px;

		}


	}

}