/* =============================================================================
    Toggle
    ========================================================================== */

.cd-primary-nav-trigger {

  display: block;
  visibility: visible;
  position: absolute;
  top: 21px;
  right: 0;
  width: 30px;
  height: 30px;
  background-color: transparent;
  background-image: none;
  cursor: pointer;
  z-index: 3;

  &:before {

    content: 'MENU';
    position: absolute;
    top: -1px;
    right: 40px;
    font-size: $font-size-base;
    color: $brand-secondary;
    text-transform: uppercase;
    font-weight: 700;

  }

  .cd-menu-icon {
    /* this span is the central line of the menu icon */
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: 30px;
    height: 2px;
    background-color: $brand-secondary;
    -webkit-transition: background-color 0.3s;
    -moz-transition: background-color 0.3s;
    transition: background-color 0.3s;
    /* these are the upper and lower lines in the menu icon */

    &:before, &:after {

    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $brand-secondary;
    right: 0;
    -webkit-transition: -webkit-transform .3s, top .3s, background-color 0s;
    -moz-transition: -moz-transform .3s, top .3s, background-color 0s;
    transition: transform .3s, top .3s, background-color 0s;

    }

    &:before {top: -5px;}
    &:after {top: 5px;}

  }

}

.cd-primary-nav-trigger .cd-menu-icon.is-clicked {
  background-color: rgba(255, 255, 255, 0);
}
.cd-primary-nav-trigger .cd-menu-icon.is-clicked::before, .cd-primary-nav-trigger .cd-menu-icon.is-clicked::after {
  background-color: white;
}
.cd-primary-nav-trigger .cd-menu-icon.is-clicked::before {
  top: 0;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.cd-primary-nav-trigger .cd-menu-icon.is-clicked::after {
  top: 0;
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);
}

header.menu-is-open {

  .cd-primary-nav-trigger {

    &:before {

      display: none;
      visibility: hidden;

    }

  }

}

@include gridle_state ( md lg ) {

  header {

    .cd-primary-nav-trigger {

      display:none;
      visibility:hidden;

    }

  }

  header.menu-is-open {

    .cd-primary-nav-trigger {

      display:block;
      visibility:visible;

    }

  }
  
}



/* =============================================================================
    Overlay
    ========================================================================== */

.cd-primary-nav {
  /* by default it's hidden - on top of the viewport */
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(2, 23, 37, 0.98);
  z-index: 2;
  text-align: center;
  padding: 50px 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: auto;
  /* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  transition-duration: 0.4s;
}
.cd-primary-nav li {
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: .2em 0;
  text-transform: capitalize;
}
.cd-primary-nav a {
  display: inline-block;
  padding: .4em 1em;
  border-radius: 0.25em;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  transition: background 0.2s;
}
.cd-primary-nav li.brand a {

  @extend .ir;

  margin: 0 auto; width: 190px; height: 60px; background-size: 190px 60px; background-image: url(../images/logo.png);

  @include gridle_state ( tb md lg ) {

    width: 280px; height: 89px; background-size: 280px 89px;

  }

}

.no-touch .cd-primary-nav a:hover {
  background-color: #e36767;
}
.cd-primary-nav .cd-label {
  color: #06446e;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  margin: 2.4em 0 .8em;
}
.cd-primary-nav .cd-social {
  display: inline-block;
  margin: 0 .4em;
}
.cd-primary-nav .cd-social a {
  width: 44px;
  height: 44px;
  padding: 0;
  background-image: url("../img/cd-socials.svg");
  background-repeat: no-repeat;
  /* image replacement */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}
.cd-primary-nav .cd-facebook a {
  background-position: 0 0;
}
.cd-primary-nav .cd-instagram a {
  background-position: -44px 0;
}
.cd-primary-nav .cd-dribbble a {
  background-position: -88px 0;
}
.cd-primary-nav .cd-twitter a {
  background-position: -132px 0;
}
.cd-primary-nav.is-visible {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
@media only screen and (min-width: 768px) {
  .cd-primary-nav {
    padding: 80px 0;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-primary-nav li {
    font-size: 30px;
    font-size: 1.875rem;
  }
  .cd-primary-nav .cd-label {
    font-size: 16px;
    font-size: 1rem;
  }
}