/* =============================================================================
	Reset the box-sizing
	========================================================================== */

* {@include box-sizing(border-box);}
*:before, *:after {@include box-sizing(border-box);}

/* =============================================================================
	Base
	========================================================================== */

html {font-size:100%; overflow-y:scroll;}
body {margin:0; background:$body-bg; font-size:$font-size-base; line-height:$line-height-base;

	@include body-bg-image;
	background-image: url('../images/background.jpg');

}
body, button, input, select, textarea {font-family:$font-family-base; color:$text-color; font-weight:$font-family-weight;}

::-moz-selection {background:$brand-primary; color:$white; text-shadow:none;}
::selection {background:$brand-primary; color:$white; text-shadow:none;}

/* =============================================================================
	Links
	========================================================================== */

a {color:$link-color; text-decoration:none;
	&:hover, &:focus {color:$link-hover-color; text-decoration:$link-hover-decoration;}
	&:focus, &:active {outline:0;}
}

/* =============================================================================
	Typography
	========================================================================== */

abbr[title] {border-bottom:1px dotted;}
b, strong {font-weight:bold;}
blockquote {margin:1em 40px;}
dfn {font-style: italic;}
hr {display:block; height:1px; border:0; border-top:1px solid $hr-border; margin:1em 0; padding:0; }
ins {background:$ins-bg; color:$ins-color; text-decoration:none;}
mark {background:$mark-bg; color:$ins-color; font-style:italic; font-weight:bold;}
pre, code, kbd, samp {font-family:$font-family-monospace; _font-family:$font-family-monospace; font-size:$font-size-base;}
pre {white-space:pre; white-space:pre-wrap; word-wrap:break-word;}
q {quotes:none;
	&:before, &:after {content:""; content:none;}
}
sub, sup {font-size:75%; line-height:0; position:relative; vertical-align:baseline;}
sup {top:-0.5em;}
sub {bottom:-0.25em;}

/* =============================================================================
	Lists
	========================================================================== */

ul, ol, dd, nav ul, nav ol {margin:0; padding:0; list-style:none; list-style-image:none;}

/* =============================================================================
	Embedded content
	========================================================================== */

img {border:0; -ms-interpolation-mode:bicubic; vertical-align:middle;}
svg:not(:root) {overflow:hidden;}
audio, canvas, iframe, img, svg, video {vertical-align: middle;}

/* =============================================================================
	Figures
	========================================================================== */

figure {margin:0;}

/* =============================================================================
	Forms
	========================================================================== */

form {margin:0;}
fieldset {border:0; margin:0; padding:0;}
label {cursor:pointer;}
legend {border:0; *margin-left:-7px; padding:0;}
button, input, select, textarea {font-size:100%; margin:0; vertical-align:middle; *vertical-align:middle;}
button, input {line-height:normal; *overflow:visible;}
table button, table input {*overflow:auto;}
button, input[type="button"], input[type="reset"], input[type="submit"] {cursor:pointer; -webkit-appearance:button;}
input[type="checkbox"], input[type="radio"] {box-sizing:border-box;}
input[type="search"] {-webkit-appearance:textfield; -moz-box-sizing:content-box; -webkit-box-sizing:content-box; box-sizing:content-box;}
input[type="search"]::-webkit-search-decoration {-webkit-appearance:none;}
button::-moz-focus-inner, input::-moz-focus-inner {border:0; padding:0;}
textarea {overflow:auto; vertical-align:top; resize:vertical;}
input:valid, textarea:valid {}
input:invalid, textarea:invalid {background-color:#f0dddd;}