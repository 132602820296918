/* ---------------------------------------------------------- */
/* https://gist.github.com/marcedwards/3446599                */
/* A media query that captures:                               */
/*                                                            */
/* - Retina iOS devices                                       */
/* - Retina Macs running Safari                               */
/* - High DPI Windows PCs running IE 8 and above              */
/* - Low DPI Windows PCs running IE, zoomed in                */
/* - Low DPI Windows PCs and Macs running Firefox, zoomed in  */
/* - Android hdpi devices and above                           */
/* - Android tvdpi devices, including Google Nexus 7          */
/* - Chrome running on high DPI Macs and PCs                  */
/* - Opera running on high DPI Macs, PCs and mobile devices   */
/*                                                            */
/* Please note that that this code assumes you'll swap a      */
/* 2× version of your images. If you'd like to supply         */
/* finer increments, other thresholds might be appropriate.   */
/*                                                            */
/* A test for CSS pixel densites can be found here:           */
/* http://bjango.com/articles/min-device-pixel-ratio/         */
/*                                                            */
/*                                  @marcedwards from @bjango */
/*                                                            */
/* ---------------------------------------------------------- */

@media	only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi)
	{

		/* ==========================================================================
		Header
		========================================================================== */

		header {

			.brand {background-image:url(../images/logo@2x.png);}

		}

		/* ==========================================================================
		Page
		========================================================================== */

		.page-content {

			ul.features {

				li {background-image: url(../images/bullet@2x.png);}

			}

		}

		ul#accordion {

			li {

				a {

					&:before {background-image:url(../images/arrow-right@2x.png);}

				}

				a.active {

					&:before {background-image:url(../images/arrow-down@2x.png);}

				}

			}

		}

		/* ==========================================================================
		Aside
		========================================================================== */

		.video {

			a {

				background-image: url(../images/video-play.png);

			}

		}

		/* =============================================================================
		End
		========================================================================== */

	}
