/* =============================================================================
	Typography

	Proxima Nova

	Light 	"proxima-nova" 	300 	normal 	
	Light Italic 	"proxima-nova" 	300 	italic 	
	Regular 	"proxima-nova" 	400 	normal 	
	Regular Italic 	"proxima-nova" 	400 	italic 	
	Semibold 	"proxima-nova" 	600 	normal 	
	Semibold Italic 	"proxima-nova" 	600 	italic 	
	Bold 	"proxima-nova" 	700 	normal 	
	Bold Italic 	"proxima-nova" 	700 	italic 	
	Extrabold 	"proxima-nova" 	800 	normal

	========================================================================== */
	
@font-face {

}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {margin:$line-height-computed 0 $line-height-computed / 1.5; padding:0; font-family:$headings-font-family; font-weight:$headings-font-weight; line-height:$headings-line-height; font-style:normal; color:$headings-color; text-transform: uppercase; text-rendering:optimizelegibility;
	small, .small {font-weight:normal; line-height:1; color:$headings-small-color;}
}

h1, .h1 {font-size:$font-size-h1;}
h2, .h2 {font-size:$font-size-h2;}
h3, .h3 {font-size:$font-size-h3;}
h4, .h4 {font-size:$font-size-h4;}
h5, .h5 {font-size:$font-size-h5;}
h6, .h6 {font-size:$font-size-h6;}

h1 small, .h1 small {font-size:$font-size-small-h1;}
h2 small, .h2 small {font-size:$font-size-small-h2;}
h3 small, .h3 small {font-size:$font-size-small-h3;}
h4 small, .h4 small {font-size:$font-size-small-h4;}
h5 small, .h5 small {font-size:$font-size-small-h5;}
h6 small, .h6 small {font-size:$font-size-small-h6;}

p {margin: 0 0 $line-height-computed / 1.5; font-family:inherit; font-size:$font-size-base; font-weight:400; line-height:$line-height-base;

}

small {font-size:$font-size-small;}
span {color:$brand-primary;}

/* =============================================================================
	Lists
	========================================================================== */

// Unordered and Ordered lists
ul,
ol {
	margin-top: 0;
	margin-bottom: ($line-height-computed / 1.5);
	ul,
	ol {
		margin-bottom: 0;
	}
}

// List options

// [converter] extracted from `.list-unstyled` for libsass compatibility
@mixin list-unstyled {
	padding-left: 0;
	list-style: none;
}
// [converter] extracted as `@mixin list-unstyled` for libsass compatibility
.list-unstyled {
	@include list-unstyled;
}


// Inline turns list items into inline-block
.list-inline {
	@include list-unstyled;
	margin-left: -5px;

	> li {
		display: inline-block;
		padding-left: 5px;
		padding-right: 5px;
	}
}

// Description Lists
dl {
	margin-top: 0; // Remove browser default
	margin-bottom: $line-height-computed;
}
dt,
dd {
	line-height: $line-height-base;
}
dt {
	font-weight: bold;
}
dd {
	margin-left: 0; // Undo browser default
}


article {

	ul.bullets {

		position:relative;
		margin:24px 0;
		margin-left:24px;

		li {

		position:relative;
		margin-bottom:8px;
		list-style:none;
		list-style-position:outsite;
		line-height:24px;

		&:before {content:"\2022"; position:absolute; left:-15px; color:$brand-primary; font-weight:700;}

		}

	}	

}

/* =============================================================================
	Blockquotes
	========================================================================== */

blockquote {position:relative; margin:0 0 $line-height-computed; padding:($line-height-computed / 1.5) $line-height-computed; font-family:$font-family-serif; font-style:italic;
	p {margin-bottom: $line-height-computed / 1.5; font-size:$blockquote-font-size; text-indent:0;}

	p:first-child {

		text-indent:20px;

		&:before {position:absolute; top:-50px; left:-20px; content:'\201C'; font-family:$font-family-serif; font-size:100px; color:$brand-primary; font-style:normal;}

	}

	&:after {position:absolute; bottom: -70px; right: -10px; content:'\201D'; font-family:$font-family-serif; font-size:100px; color:$brand-primary; font-style:normal;}

}











